import store from "store";
import lang from "store/models/lang";
import translator from "translator";

const transcript = {
  gender: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "male":
          return translator["Мужской"][store.getState().lang];
        case "female":
          return translator["Женский"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  is_disaster: {
    getKeyFromValue: (text: boolean) => {
      switch (text) {
        case true:
          return translator["Да"][store.getState().lang];
        case false:
          return translator["Нет кредит"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  has_guarantor: {
    getKeyFromValue: (text: boolean) => {
      switch (text) {
        case true:
          return translator["Да"][store.getState().lang];
        case false:
          return translator["Нет кредит"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  has_collateral: {
    getKeyFromValue: (text: boolean) => {
      switch (text) {
        case true:
          return translator["Да"][store.getState().lang];
        case false:
          return translator["Нет кредит"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  marital_status: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "divorced":
        case "Divorced":
          return translator["Разведён/Разведена"][store.getState().lang];
        case "married":
        case "Married":
          return translator["Женат/Замужем"][store.getState().lang];
        case "widow/widower":
        case "Widow/WIDOWER":
          return translator["Вдовец/Вдова"][store.getState().lang];
        case "single":
        case "Single":
          return translator["Не женат/Не замужем"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  sector: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "agriculture":
        case "Agriculture":
          return translator["Сельское хозяйство"][store.getState().lang];
        case "consumer":
        case "Consumer":
          return translator["Потребительский"][store.getState().lang];
        case "mortgage":
        case "Mortgage":
          return translator["Ипотека"][store.getState().lang];
        case "production":
        case "Production":
          return translator["Производство"][store.getState().lang];
        case "retail":
          return translator["Розничная торговля"][store.getState().lang];
        case "service":
        case "Services":
          return translator["Услуги"][store.getState().lang];
        case "trade":
        case "Trade":
          return translator["Торговля"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  work_place: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "government_agency":
          return translator["Государственное учреждение"][
            store.getState().lang
          ];
        case "private_company":
        case "Private company":
          return translator["Частная компания"][store.getState().lang];
        case "public_organization":
          return translator["Общественная организация"][store.getState().lang];
        case "individual_entrepreneur":
          return translator["Индивидуальный предприниматель"][
            store.getState().lang
          ];
        case "other":
        case "Other":
          return translator["Другой"][store.getState().lang];
        case "none":
        case "None":
          return translator["Нет"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  phone_type: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "ios":
        case "IOS":
          return translator["IOS"][store.getState().lang];
        case "android":
        case "Android":
          return translator["Android"][store.getState().lang];
        case "other":
        case "Other":
          return translator["Другой"][store.getState().lang];
        case "any":
        case "Any":
          return translator["Нет телефон"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  education: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "higher_education":
          // case "Higher Education":
          return translator["Высшее образование"][store.getState().lang];
        case "incomplete_secondary_education":
          // case "Incomplete Secondary Education":
          return translator["Неполное среднее образование"][
            store.getState().lang
          ];
        case "secondary_education":
          // case "Secondary Education":
          return translator["Среднее образование"][store.getState().lang];
        case "secondary_with_specialisation":
          // case "Secondary Education with Specialisation":
          return translator["Среднее специальное образование"][
            store.getState().lang
          ];
        case "other_education":
          // case "Other Education":
          return translator["Другое образование"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  job_status: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "employed":
        case "Employed":
          return translator["Занят"][store.getState().lang];
        case "unemployed":
        case "Unemployed":
          return translator["Безработный"][store.getState().lang];
        case "other":
        case "Other":
          return translator["Другой"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  // branch: {
  //   getKeyFromValue: (text: string) => {
  //     switch (text) {
  //       case "almaty":
  //         return translator["Ашт"][store.getState().lang];
  //       case "shymkent":
  //         return translator["Айни"][store.getState().lang];
  //       case "kyzylorda":
  //         return translator["Б.гафуров"][store.getState().lang];
  //       case "turkistan":
  //         return translator["Балчувон"][store.getState().lang];
  //       case "pavlodar":
  //         return translator["Балхи"][store.getState().lang];
  //       // case "taldykorgan":
  //       //   return translator["Талдыкорган"][store.getState().lang];
  //       // case "jambyl":
  //       //   return translator["Жамбыл"][store.getState().lang];
  //       // case "kostanay":
  //       //   return translator["Костанай"][store.getState().lang];
  //       // case "akmola":
  //       //   return translator["Акмолинская"][store.getState().lang];
  //       // case "semei":
  //       //   return translator["Семей"][store.getState().lang];
  //       // case "astana":
  //       //   return translator["Астана"][store.getState().lang];
  //       // case "east-kazakhstan":
  //       //   return translator["Восточно-Казахстанская"][store.getState().lang];
  //       // case "aktobe":
  //       //   return translator["Актобе"][store.getState().lang];
  //       // case "karaganda":
  //       //   return translator["Караганда"][store.getState().lang];
  //       // case "north-kazakhstan":
  //       //   return translator["Северо-Казахстанская"][store.getState().lang];
  //       // case "west-kazakhstan":
  //       //   return translator["Западно-Казахстанская"][store.getState().lang];
  //       default:
  //         return "";
  //     }
  //   },
  // },
  city_rural: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "city":
          return translator["Город"][store.getState().lang];
        case "rural":
          return translator["Сельская местность"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  credit_type: {
    getKeyFromValue: (text: string | number) => {
      switch (text) {
        case "краткосрочный":
          return translator["Краткосрочный"][store.getState().lang];
        case "среднесрочный":
          return translator["Среднесрочный"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  clientTypeCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "New":
          return 0;
        case "Repeat":
          return 1;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 0:
        case "0":
          return translator["Новый"][store.getState().lang];
        case 1:
        case "1":
          return translator["Повторный"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  district: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "asht":
          return translator["Ашт"][store.getState().lang];
        case "ayni":
          return translator["Айни"][store.getState().lang];
        case "b.gafurov":
          return translator["Б.гафуров"][store.getState().lang];
        case "baljuvon":
          return translator["Бальджуван"][store.getState().lang];
        case "balkhi":
          return translator["Балхи"][store.getState().lang];
        case "bokhtar":
          return translator["Бохтар"][store.getState().lang];
        case "buston":
          return translator["Бустон"][store.getState().lang];
        case "dangara":
          return translator["Дангара"][store.getState().lang];
        case "chirik":
          return translator["Чирик"][store.getState().lang];
        case "darvoz":
          return translator["Дарвоз"][store.getState().lang];
        case "devashtich":
          return translator["Деваштич"][store.getState().lang];
        case "dushanbe":
          return translator["Душанбе"][store.getState().lang];
        case "dusti":
          return translator["Дусти"][store.getState().lang];
        case "farhor":
          return translator["Фархор"][store.getState().lang];
        case "farovon":
          return translator["Фараван"][store.getState().lang];
        case "fayzobod":
          return translator["Файзабад"][store.getState().lang];
        case "firdavsi":
          return translator["Фирдавси"][store.getState().lang];
        case "guliston":
          return translator["Гулистан"][store.getState().lang];
        case "hamadoni":
          return translator["Хамадони"][store.getState().lang];
        case "hissor":
          return translator["Хиссар"][store.getState().lang];
        case "isfara":
          return translator["Исфара"][store.getState().lang];
        case "istaravshan":
          return translator["Истаравшан"][store.getState().lang];
        case "istiqlol":
          return translator["Истикляль"][store.getState().lang];
        case "istravshan":
          return translator["Истаравшан"][store.getState().lang];
        case "j.rasulov":
          return translator["Дж.Расулов"][store.getState().lang];
        case "jayhun":
          return translator["Джайхун"][store.getState().lang];
        case "jomi":
          return translator["Джоми"][store.getState().lang];
        case "kangurt":
          return translator["Кангурт"][store.getState().lang];
        case "khorug":
          return translator["Хоруг"][store.getState().lang];
        case "khovaling":
          return translator["Хавалинг"][store.getState().lang];
        case "khujand":
          return translator["Худжанд"][store.getState().lang];
        case "khuroson":
          return translator["Хуросан"][store.getState().lang];
        case "kubodiyon":
          return translator["Кубодян"][store.getState().lang];
        case "konibodom":
          return translator["Конибодом"][store.getState().lang];
        case "kuhistoni_mastchoh":
          return translator["Мастчох"][store.getState().lang];
        case "kulob":
          return translator["Куляб"][store.getState().lang];
        case "muminobod":
          return translator["Муминабад"][store.getState().lang];
        case "murgob":
          return translator["Мургоб"][store.getState().lang];
        case "norak":
          return translator["Норак"][store.getState().lang];
        case "nurobod":
          return translator["Нуробод"][store.getState().lang];
        case "panj":
          return translator["Панч"][store.getState().lang];
        case "panjakent":
          return translator["Панджакет"][store.getState().lang];
        case "rasht":
          return translator["Рашт"][store.getState().lang];
        case "rogun":
          return translator["Рогун"][store.getState().lang];
        case "rudaki":
          return translator["Рудаки"][store.getState().lang];
        case "tursunzoda":
          return translator["Турсунзаде"][store.getState().lang];
        case "vahdat":
          return translator["Вахдат"][store.getState().lang];
        case "vakhsh":
          return translator["Вахш"][store.getState().lang];
        case "vanj":
          return translator["Вандж"][store.getState().lang];
        case "varzob":
          return translator["Варзоб"][store.getState().lang];
        case "vose":
          return translator["Восе"][store.getState().lang];
        case "sangtuda":
          return translator["Cангтуда"][store.getState().lang];
        case "sangvor":
          return translator["Сангвор"][store.getState().lang];
        case "shahrinav":
          return translator["Шахринав"][store.getState().lang];
        case "shahrituz":
          return translator["Шахритуз"][store.getState().lang];
        case "temurmalik":
          return translator["Темурмалик"][store.getState().lang];
        case "tojikobod":
          return translator["Таджикабад"][store.getState().lang];
        case "kushoniyon":
          return translator["Кушониен"][store.getState().lang];
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "Abay":
          return "Butterscotch";
        case "Akmarjan":
          return "Camera";
        case "Aksukent":
          return "Headphone";
        case "Aktobe":
          return "Campus";
        case "Alatau":
          return "Catfish";
        case "Aral":
          return "Carsick";
        case "Aris":
          return "Celebrate";
        case "Asa":
          return "Celery";
        case "Asyk_ata":
          return "Citizen";
        case "Atakent":
          return "Coloring";
        case "Atameken":
          return "Compact";
        case "Ayteke_bi":
          return "Engine";
        case "B_momishili":
          return "Dark";
        case "Baydibek":
          return "Damage";
        case "Bayirkum":
          return "Dangerous";
        case "Bazarkakpa":
          return "Decorum";
        case "Birlik":
          return "Endorse";
        case "Center":
          return "Engender";
        case "Darbaza":
          return "Erratic";
        case "Esik":
          return "Envelope";
        case "Eurasia":
          return "Etymology";
        case "Iassy":
          return "Eyewitness";
        case "Ikan":
          return "Eulogy";
        case "Intimak":
          return "Fish";
        case "Janakorgan":
          return "Food";
        case "Janatas":
          return "Foreclose";
        case "Jarkent":
          return "Phone";
        case "Jetisay":
          return "Perpetrator";
        case "Juldiz":
          return "Parenthesis";
        case "Kaplanbek":
          return "Owl";
        case "Karabulak":
          return "Noisome";
        case "Karakum":
          return "Newborn";
        case "Karasu":
          return "Nephogram";
        case "Karatas":
          return "Near";
        case "Karatau":
          return "Moonwalk";
        case "Karnak":
          return "Mine";
        case "Kaskelen":
          return "Marshmallow";
        case "Kaytpas":
          return "Mascot";
        case "Kazakhstan":
          return "Mascot";
        case "Kazali":
          return "Mascot";
        case "Kazygurt":
          return "Zestful";
        case "Kentau":
          return "You";
        case "Keruen":
          return "Xerox";
        case "Kizilkum":
          return "Wednesday";
        case "Koksu":
          return "Way";
        case "Kolkent":
          return "Wealth";
        case "Korday":
          return "Underestimate";
        case "Kulan":
          return "Termination";
        case "Kurkeles":
          return "Tree";
        case "Lenger":
          return "Timesaving";
        case "Maktaaral":
          return "Tennis";
        case "Mankent":
          return "Teamwork";
        case "Merke":
          return "Lifeline";
        case "Mirzakent":
          return "Lifeline";
        case "Nursat":
          return "Significance";
        case "Orangay":
          return "Jackpot";
        case "Otirar":
          return "Ship";
        case "Rabat":
          return "Invest";
        case "Samal":
          return "Shellfish";
        case "Sariagash":
          return "Interim";
        case "Sastobe":
          return "Састобе";
        case "Sayram":
          return "Snapshot";
        case "Sayran":
          return "Inimical";
        case "Shardara":
          return "Reflection";
        case "Shaulder":
          return "Indulge";
        case "Shelek":
          return "Inflatable";
        case "Shieli":
          return "Ring";
        case "Shubarsu":
          return "Reporter";
        case "Sozak":
          return "Indulge";
        case "T_Riskulova":
          return "Handbook";
        case "Taldikorgan":
          return "Grandnieces";
        case "Taraz":
          return "Recount";
        case "Tekesu":
          return "Government";
        case "Temirlan":
          return "Goofy";
        case "Tole_bi":
          return "Publish";
        case "Tortkol":
          return "Gigantic";
        case "Turan":
          return "Future";
        case "Turkestan":
          return "Protect";
        case "shymkala_msb":
          return "Picnic";
        default:
          return "";
      }
    },
  },
  branchEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Алматинский областной филиал":
          return "Almaty_oblast";
        case "Алматинский городской филиал":
          return "Almaty_town";
        case "Жамбылский областной филиал":
          return "Jambil";
        case "Кызылординский областной филиал":
          return "Kizilorda";
        case "Махтаральский районный филиал":
          return "Makhtaral";
        case "Ордабасынский районный филиал":
          return "Ordabasin";
        case "Сарыагашский районный филиал":
          return "Sariagash";
        case "Сайрамский районный филиал":
          return "Sayram";
        case "Шымкент":
          return "Shymkent";
        case "Туркестанский городской филиал":
          return "Turkestan";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "Almaty_oblast":
          return "Abjure";
        case "Almaty_town":
          return "Agonistic";
        case "Jambil":
          return "Airline";
        case "Kizilorda":
          return "Bandit";
        case "Makhtaral":
          return "Banquet";
        case "Ordabasin":
          return "Binoculars";
        case "Sariagash":
          return "Biologist";
        case "Sayram":
          return "Blackboard";
        case "Shymkent":
          return "Board";
        case "Turkestan":
          return "Bookworm";
        default:
          return "";
      }
    },
  },
  workingAreaEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Agriculture":
          return "agriculture";
        case "Livestock":
          return "animal_husbandry";
        case "Construction":
          return "building";
        case "Driver":
          return "driver";
        case "Employer":
          return "employment";
        case "Individual entrepreneur":
          return "entrepreneur";
        case "Housewife":
          return "housewife";
        case "Production":
          return "manufacture";
        case "Medicine":
          return "medicine";
        case "Military":
          return "military";
        case "Civil servant":
          return "public_officer";
        case "Retired":
          return "retired";
        case "Employment":
          return "services";
        case "Trade":
          return "trade";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "agriculture":
          return "Agriculture";
        case "animal_husbandry":
          return "Livestock";
        case "building":
          return "Construction";
        case "driver":
          return "Driver";
        case "employment":
          return "Employer";
        case "entrepreneur":
          return "Individual entrepreneur";
        case "housewife":
          return "Housewife";
        case "manufacture":
          return "Production";
        case "medicine":
          return "Medicine";
        case "military":
          return "Military";
        case "public_officer":
          return "Civil servant";
        case "retired":
          return "Retired";
        case "services":
          return "Employment";
        case "trade":
          return "Trade";
        default:
          return "";
      }
    },
  },
  educationEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Higher":
          return "higher_education";
        case "Incomplete secondary education":
          return "incomplete_secondary_education";
        case "Secondary specialty":
          return "secondary_education";
        case "Secondary with specialisation":
          return "secondary_with_specialisation";
        case "Other education":
          return "other_education";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "higher_education":
          return translator["Высшее образование"][store.getState().lang];
        case "incomplete_secondary_education":
          return translator["Неполное среднее образование"][
            store.getState().lang
          ];
        case "secondary_education":
          return translator["Среднее образование"][store.getState().lang];
        case "secondary_with_specialisation":
          return translator["Среднее специальное образование"][
            store.getState().lang
          ];
        case "other_education":
          return translator["Другое образование"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  genderСode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Male":
          return 1;
        case "Female":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return translator["Мужской"][store.getState().lang];
        case 0:
        case "0":
          return translator["Женский"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  maritalStatusEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Divorced / Divorced":
          return "divorced";
        case "Married / Married":
          return "married";
        case "Single / Single":
          return "single";
        case "Widow / Widower":
          return "widow";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case "divorced":
          return translator["Разведён/Разведена"][store.getState().lang];
        case "married":
          return translator["Женат/Замужем"][store.getState().lang];
        case "single":
          return translator["Не женат/Не замужем"][store.getState().lang];
        case "widow":
          return translator["Вдовец/Вдова"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  workExperienceCat: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "From 1 to 3 years":
          return "from1to3";
        case "From 3 years to 5 years":
          return "from3to5";
        case "Больше 5 лет":
          return "morethan5";
        case "Нет опыта":
          return "no_exp";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case "from1to3":
          return translator["От 1 до 3 лет"][store.getState().lang];
        case "from3to5":
          return translator["От 3 до 5 лет"][store.getState().lang];
        case "morethan5":
          return translator["Более 5 лет"][store.getState().lang];
        case "no_exp":
          return translator["Нет опыта"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  isCarСode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "rent":
          return translator["Аренда"][store.getState().lang];
        case "ownership":
          return translator["Собственность"][store.getState().lang];
        case "on_credit":
          return translator["В кредите"][store.getState().lang];
        case "other":
          return translator["Другой"][store.getState().lang];
        case "none":
          return translator["Нет"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  isHomeСode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "rent":
        case "Rent":
          return translator["Аренда"][store.getState().lang];
        case "ownership":
        case "Ownership":
          return translator["Собственность"][store.getState().lang];
        case "on_credit":
        case "On_credit":
          return translator["В кредите"][store.getState().lang];
        case "other":
        case "Other":
          return translator["Другой"][store.getState().lang];
        case "none":
        case "None":
          return translator["Нет"][store.getState().lang];
        default:
          return "";
      }
    },
  },

  coBorrowerCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Yes":
          return 1;
        case "No":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return translator["Да"][store.getState().lang];
        case 0:
        case "0":
          return translator["Нет"][store.getState().lang];
        default:
          return "";
      }
    },
  },
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "admin":
          return translator["Админ"][store.getState().lang];
        case "root":
          return "ROOT";
        default:
          return translator["Кредитный эксперт"][store.getState().lang];
      }
    },
  },
  bkit_score: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "a":
          return "A"
        case "b":
          return "B"
        case "c":
          return "A"
        case "d":
          return "A"
        case "e":
          return "A"
        case "f":
          return "A"
        case "g":
          return "A"
        case "h":
          return "A"
        case "i":
          return "A"
        case "j":
          return "A"
        case "k":
          return "A"
        case "l":
          return "A"
        case "m":
          return "A"
        case "n":
          return "A"
        case "o":
          return "A"
        case "p":
          return "A"
        case "q":
          return "A"
        case "r":
          return "A"
        case "s":
          return "A"
        case "t":
          return "A"
        case "u":
          return "A"
        case "v":
          return "A"
        case "w":
          return "A"
        case "x":
          return "A"
        case "y":
          return "A"
        case "z":
          return "A"
        case "none":
          return translator["Нет"][store.getState().lang]
        default:
          return "";
      }
    },
  },
};

export default transcript;
