import { Models, RematchDispatch, RematchRootState } from "@rematch/core";
import application from "./application";
import user from "./user";
import lang from "./lang";

export interface IRootModel extends Models<IRootModel> {
  application: typeof application;
  user: typeof user;
  lang: typeof lang;
}
export type TDispatch = RematchDispatch<IRootModel>;
export type TRootState = RematchRootState<IRootModel>;
export const models: IRootModel = { application, user, lang };
