import { useSelector } from "store/hooks";
import styles from "./collection.module.scss";
import translator from "translator";
import { useEffect } from "react";
import axios from "axios";
import React from "react";
import global from "global";
import Loading from "components/loading";

const token = "2ZTUCMlPTElYfKEGBUqZRtHE8H8fM1Wk";

const instance = axios.create({
  baseURL: "https://spitamen.westeurope.cloudapp.azure.com",
  headers: {
    "Content-Type": "application/json",
    "api-partner-token": `${token}`,
  },
});

function convertEducationType(type: string) {
  switch (type) {
    case "higher_education":
      return "Higher Education";
    case "incomplete_secondary_education":
      return "Incomplete Secondary Education";
    case "secondary_education":
      return "Secondary Education";
    case "secondary_with_specialisation":
      return "Secondary Education with Specialisation";
    case "other_education":
      return "Other Education";
    default:
      return "Unknown Education Type";
  }
}

const Collection = () => {
  // const [collectionData, setCollectionData] =
  //   React.useState<global.collecation_data>();
  const [tableData, setTableData] = React.useState<global.table_data>();
  const lang = useSelector((state) => state.lang);
  const contextApplication = useSelector((state) => state.application);
  const [pending, setPending] = React.useState(false);

  // useEffect(() => {
  //   if (!contextApplication.data?.scoring_log) return;
  //   setPending(true);
  //   instance
  //     .post("/scoring_regression", {
  //       age: Number(contextApplication.data?.scoring_log?.age),
  //       gender: contextApplication.data?.scoring_log?.gender === "male" ? 1 : 0,
  //       amount: Number(contextApplication.data?.amount),
  //       duration: Number(contextApplication.data?.duration),
  //       credit_history_count: Number(
  //         contextApplication.data?.scoring_log?.credit_history_count
  //       ),
  //       dependants: Number(contextApplication.data?.scoring_log?.dependants),
  //       int_rate: Number(contextApplication.data?.int_rate) / 100,
  //       work_exp: Number(contextApplication.data?.scoring_log?.work_exp),
  //       mon_income: Number(contextApplication.data?.scoring_log?.mon_income),
  //       sector:
  //         contextApplication.data?.scoring_log?.sector[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.sector.slice(1),
  //       marital_status:
  //         contextApplication.data?.scoring_log?.marital_status[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.marital_status.slice(1),
  //       phone_type:
  //         (contextApplication.data?.scoring_log?.phone_type === "ios" &&
  //           contextApplication.data?.scoring_log?.phone_type.toUpperCase()) ||
  //         contextApplication.data?.scoring_log?.phone_type[0].toUpperCase() +
  //           contextApplication.data?.scoring_log?.phone_type.slice(1),
  //       education: convertEducationType(
  //         contextApplication.data.scoring_log.education
  //       ),
  //       job_status:
  //         contextApplication.data?.scoring_log?.job_status[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.job_status.slice(1),
  //       work_place: "Private company",
  //       has_transport: contextApplication.data?.scoring_log?.has_transport,
  //       has_house:
  //         contextApplication.data?.scoring_log?.has_house[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.has_house.slice(1),
  //       threshold: contextApplication.data?.scoring_log?.threshold,
  //     })
  //     .then((res) => setCollectionData(res.data))
  //     .then((data) => localStorage.setItem("collection", JSON.stringify(data)))
  //     .finally(() => setPending(false));

  // }, [
  //   contextApplication.data?.amount,
  //   contextApplication.data?.duration,
  //   contextApplication.data?.int_rate,
  //   contextApplication.data?.scoring_log,
  // ]);
  useEffect(() => {
    if (!contextApplication.data?.scoring_log) return;
    setPending(true);
    instance
      .post("/scoring_survival", {
        age: Number(contextApplication.data?.scoring_log?.age),
        gender: contextApplication.data?.scoring_log?.gender === "male" ? 1 : 0,
        amount: Number(contextApplication.data?.amount),
        duration: Number(contextApplication.data?.duration),
        credit_history_count: Number(
          contextApplication.data?.scoring_log?.credit_history_count
        ),
        dependants: Number(contextApplication.data?.scoring_log?.dependants),
        int_rate: Number(contextApplication.data?.int_rate) / 100,
        work_exp: Number(contextApplication.data?.scoring_log?.work_exp),
        mon_income: Number(contextApplication.data?.scoring_log?.mon_income),
        sector:
          contextApplication.data?.scoring_log?.sector[0].toUpperCase() +
          contextApplication.data?.scoring_log?.sector.slice(1),
        marital_status:
          contextApplication.data?.scoring_log?.marital_status[0].toUpperCase() +
          contextApplication.data?.scoring_log?.marital_status.slice(1),
        phone_type:
          (contextApplication.data?.scoring_log?.phone_type === "ios" &&
            contextApplication.data?.scoring_log?.phone_type.toUpperCase()) ||
          contextApplication.data?.scoring_log?.phone_type[0].toUpperCase() +
            contextApplication.data?.scoring_log?.phone_type.slice(1),
        education: convertEducationType(
          contextApplication.data.scoring_log.education
        ),
        job_status:
          contextApplication.data?.scoring_log?.job_status[0].toUpperCase() +
          contextApplication.data?.scoring_log?.job_status.slice(1),
        work_place: "Private company",
        has_transport: contextApplication.data?.scoring_log?.has_transport,
        has_house:
          contextApplication.data?.scoring_log?.has_house[0].toUpperCase() +
          contextApplication.data?.scoring_log?.has_house.slice(1),
        threshold: contextApplication.data?.scoring_log?.threshold,
      })
      .then((res) => setTableData(res.data))
      .finally(() => setPending(false));
  }, [
    contextApplication.data?.amount,
    contextApplication.data?.duration,
    contextApplication.data?.int_rate,
    contextApplication.data?.scoring_log,
  ]);

  // function convert(str?: string) {
  //   if (!str) return;
  //   const number = Number(str.match(/\d+(\.\d+)?/g));
  //   let text = "";
  //   const suffixes = ["th", "st", "nd", "rd"];
  //   // Special case for 11, 12, and 13
  //   if (number % 100 >= 11 && number % 100 <= 13) {
  //     text = suffixes[0];
  //   }
  //   // Use the last digit to determine the suffix
  //   const lastDigit = number % 10;
  //   text = suffixes[lastDigit <= 3 ? lastDigit : 0];
  //   return number.toString() + text;
  // }

  return (
    <div className={styles.collection}>
      <h2 className={styles.collection_status}>
        {translator["Статус заявки"][lang]}
      </h2>
      {pending ? (
        <Loading />
      ) : (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{translator["Месяц"][lang]}</th>
                <th>{translator["Вероятность выплоты"][lang]}</th>
                <th>{translator["Просрочку"][lang]}</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.prediction_results &&
                Object.entries(tableData.prediction_results).map(
                  ([key, value]) => (
                    <tr key={key}>
                      <td>{parseInt(key)}</td>
                      <td>{Number(value[0] * 100).toFixed(2)}%</td>
                      <td>
                        {value.delay
                          ? translator["Да"][lang]
                          : translator["Нет кредит"][lang]}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
          {/* <h2 className={styles.collection_title}>
            {lang === "ru"
              ? collectionData?.result
              : `The client probably does the stitching in the ${convert(
                  collectionData?.result
                )}  month.`}
          </h2> */}
        </>
      )}
    </div>
  );
};

export default Collection;
