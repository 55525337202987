import React from "react";
import { AxiosError } from "axios";
import { useAlert } from "react-alert";

import Input from "components/input";
import Button from "components/button";
import RangeBox from "components/range-box";
import request from "request";
import translator from "translator";
import { useSelector } from "store/hooks";
import styles from "./settings.module.scss";

const Settings = () => {
  const alert = useAlert();
  const lang = useSelector((state) => state.lang);
  const [pending, setPending] = React.useState(false);
  const [foodInflation, setFoodInflation] = React.useState("");
  const [nonfoodInflation, setNonfoodInflation] = React.useState("");
  const [servicesInflation, setServicesInflation] = React.useState("");
  const [exrateUsdAvg, setExrateUsdAvg] = React.useState("");
  const [avrate, setAvrate] = React.useState("");
  const [gdp, setGdp] = React.useState("");
  const [cpi, setCpi] = React.useState("");
  const [brentOilPrice, setBrentOilPrice] = React.useState("");
  const [range, setRange] = React.useState(20);

  const handleSetSettings = () => {
    setPending(true);
    request
      .put("/admin/constant_values", {
        threshold: range / 100,
        food_inflation: foodInflation,
        nonfood_inflation: nonfoodInflation,
        services_inflation: servicesInflation,
        exrate_usd_avg: exrateUsdAvg,
        avrate: avrate,
        gdp: gdp,
        cpi: cpi,
        brent_oil_price: brentOilPrice,
      })
      .then(() => alert.success("Сохранено!"))
      .catch((err: AxiosError) => {
        alert.show((err.response?.data as any).detail);
      })
      .finally(() => setPending(false));
  };

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setFoodInflation(data.food_inflation);
        setNonfoodInflation(data.nonfood_inflation);
        setServicesInflation(data.services_inflation);
        setExrateUsdAvg(data.exrate_usd_avg);
        setAvrate(data.avrate);
        setGdp(data.gdp);
        setCpi(data.cpi);
        setBrentOilPrice(data.brent_oil_price);
        setRange(data.threshold * 100);
      });
  }, []);

  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>
            {translator["Настройки"][lang]}
          </div>
          <div className={styles.fieldList}>
            <Input
              title={translator["Инфляция на продовольственные товары"][lang]}
              value={foodInflation}
              step={0.1}
              type="float"
              onInput={(value) => setFoodInflation(value)}
            />
            <Input
              title={translator["Инфляция непотребительских товаров"][lang]}
              value={nonfoodInflation}
              step={0.1}
              type="float"
              onInput={(value) => setNonfoodInflation(value)}
            />
            <Input
              title={translator["Инфляция услуг"][lang]}
              value={servicesInflation}
              step={0.1}
              type="float"
              onInput={(value) => setServicesInflation(value)}
            />
            <Input
              title={translator["Курс доллара"][lang]}
              value={exrateUsdAvg}
              step={0.1}
              type="float"
              onInput={(value) => setExrateUsdAvg(value)}
            />
            <Input
              title={translator["Средний доход населения"][lang]}
              value={avrate}
              step={0.1}
              type="float"
              onInput={(value) => setAvrate(value)}
            />
            <Input
              title={translator["ВВП"][lang]}
              value={gdp}
              step={0.1}
              type="float"
              onInput={(value) => setGdp(value)}
            />
            <Input
              title={translator["Индекс потребительских цен"][lang]}
              value={cpi}
              step={0.1}
              type="float"
              onInput={(value) => setCpi(value)}
            />
            <Input
              title={translator["Цена на нефть марки Брент"][lang]}
              value={brentOilPrice}
              step={0.1}
              type="float"
              onInput={(value) => setBrentOilPrice(value)}
            />
          </div>
          <div className={styles.fieldTitle}>{translator["Порог отказа"][lang]}</div>
          <RangeBox value={range} setValue={setRange} />
        </div>
        <div className={styles.footerBox}>
          <Button pending={pending} onClick={handleSetSettings}>
            {translator["Сохранить"][lang]}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
