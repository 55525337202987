import React from "react";
import classNames from "classnames";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { useDispatch, useSelector } from "store/hooks";
import styles from "./main.module.scss";
import translator from "translator";

const MainLayout = () => {
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileOpened, setProfileOpened] = React.useState(false);
  const [langOpened, setLangOpened] = React.useState(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <Link to="/" className={styles.title}>
          zypl.score
          </Link>
          <div className={styles.tools}>
            <div
              className={classNames(
                styles.profileDropdown,
                styles.small,
                langOpened && styles.active
              )}
            >
              <button
                className={styles.profileDropdown__label}
                onClick={() => setLangOpened(!langOpened)}
              >
                {translator["Язык"][lang]}
              </button>
              {langOpened && (
                <div
                  className={styles.cover}
                  onMouseDown={() => setLangOpened(false)}
                />
              )}
              <CSSTransition
                unmountOnExit
                timeout={80}
                in={langOpened}
                classNames={{
                  enter: styles.enter,
                  enterActive: styles.enterActive,
                  exit: styles.exit,
                  exitActive: styles.exitActive,
                }}
              >
                <div className={styles.profileDropdown__menu}>
                  <button
                    type="button"
                    onClick={() => {
                      dispatch.lang.SET_LANG("ru");
                      setLangOpened(false);
                    }}
                    className={styles.profileDropdown__item}
                  >
                    {translator["Русский"][lang]}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      dispatch.lang.SET_LANG("en");
                      setLangOpened(false);
                    }}
                    className={styles.profileDropdown__item}
                  >
                    {translator["Английский"][lang]}
                  </button>
                </div>
              </CSSTransition>
            </div>
            <div
              className={classNames(
                styles.profileDropdown,
                profileOpened && styles.active
              )}
            >
              <button
                className={styles.profileDropdown__label}
                onClick={() => setProfileOpened(!profileOpened)}
              >
                {user.login}
              </button>
              {profileOpened && (
                <div
                  className={styles.cover}
                  onMouseDown={() => setProfileOpened(false)}
                />
              )}
              <CSSTransition
                unmountOnExit
                timeout={80}
                in={profileOpened}
                classNames={{
                  enter: styles.enter,
                  enterActive: styles.enterActive,
                  exit: styles.exit,
                  exitActive: styles.exitActive,
                }}
              >
                <div className={styles.profileDropdown__menu}>
                  {user.role !== "expert" && (
                    <>
                      <Link
                        onClick={() => setProfileOpened(false)}
                        to="/users"
                        className={styles.profileDropdown__item}
                      >
                        {translator["Список пользователей"][lang]}
                      </Link>
                      {/* <button onClick={() => setProfileOpened(false)} className={styles.profileDropdown__item}>
                      Сменить пароль
                    </button> */}
                      <Link
                        onClick={() => setProfileOpened(false)}
                        to="/settings"
                        className={styles.profileDropdown__item}
                      >
                        {translator["Настройки"][lang]}
                      </Link>
                    </>
                  )}
                  <button
                    className={styles.profileDropdown__item}
                    onClick={handleLogout}
                  >
                    {translator["Выйти"][lang]}
                  </button>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default MainLayout;
