import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { AxiosError } from "axios";

import LoginPanel from "components/login-pan";
import request from "request";
import styles from "./login.module.scss";

const Login = () => {
  const alert = useAlert();

  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isPending, setIsPending] = React.useState(false);

  const navigate = useNavigate();

  const handlSubmit = () => {
    setIsPending(true);
    request
      .post("/login", {
        login: username,
        password: password,
      })
      .then((res) => res.data)
      .then((data) => {
        setIsPending(false);
        localStorage.setItem("token", data?.token);
        navigate("/");
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) return alert.error("Genderьзователь не найден или заблокирован");
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
    else localStorage.clear();
  }, [navigate]);

  return (
    <div className={classNames(styles.login, isPending && styles.pending)}>
      <div className={styles.container}>
        <LoginPanel
          username={username}
          setUsername={setUserName}
          password={password}
          setPassword={setPassword}
          onSubmit={handlSubmit}
        />
      </div>
    </div>
  );
};

export default Login;
