import React from "react";

import range from "./range";
import styles from "./range.module.scss";

const Range: React.FC<range.props> = (props) => {
  const valPercent = (props.value / props.max) * 100;
  return (
    <input
      step={props.step}
      min={props.min}
      max={props.max}
      className={styles.range}
      type="range"
      value={props.value}
      onChange={(event) => props.setValue(event.target.valueAsNumber)}
      style={{
        backgroundImage: `linear-gradient(to right, #5E81AC ${valPercent}%, #D8DEE9 ${valPercent}%)`,
      }}
    />
  );
};

export default Range;
