import { init } from "@rematch/core";
import { models, TRootState } from "./models";
import { initialState } from "./utils";

const store = init({
  models,
  redux: {
    initialState: initialState.get(),
  },
});

store.subscribe(() => {
  const { application, user, lang } = store.getState();

  let state: Partial<TRootState> = {};
  state.application = application;
  state.user = user;
  state.lang = lang;

  return initialState.set(state);
});

export default store;
