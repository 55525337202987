import React from "react";
import { CSSTransition } from "react-transition-group";
import Icon from "icons";
import classNames from "classnames";
import { useSelector } from "store/hooks";
import Button from "components/button";
import translator from "translator";
import prettierNumber from "utils/prettier-number";
import styles from "./result-box.module.scss";
import modalStyles from "./components/recommendation.module.scss";
import Modal from "./components/recommendation";

const ResultBox = () => {
  const contextApplication = useSelector((state) => state.application);
  const lang = useSelector((state) => state.lang);

  const [recommendationModal, setRecommendationModal] = React.useState(false);

  const handleOpenRecommendation = () => setRecommendationModal(true);

  const selectedRecommendation = React.useMemo(() => {
    return contextApplication.data?.recommendations_log?.find(
      (rec) => rec.id === contextApplication.data?.selected_recommendation
    );
  }, [
    contextApplication.data?.selected_recommendation,
    contextApplication.data?.recommendations_log,
  ]);

  return (
    <div className={styles.resultList}>
      {contextApplication.data?.selected_recommendation && (
        <div className={styles.recommendationBox}>
          <div className={styles.recommendationBox_title}>
            {translator["Выбранная рекомендация"][lang]}
          </div>
          <div className={styles.item}>
            <div className={styles.key}>{translator["Сумма"][lang]}</div>
            <div className={styles.value}>
              {prettierNumber(Number(selectedRecommendation?.amount))}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>{translator["Длительность"][lang]}</div>
            <div className={styles.value}>
              {selectedRecommendation?.duration} {translator["мес."][lang]}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>
              {translator["Вероятность выплаты"][lang]}
            </div>
            <div className={styles.value}>
              {(Number(selectedRecommendation?.good_prob) * 100).toFixed()} %
            </div>
          </div>
        </div>
      )}
      {contextApplication.data?.scoring_log === null ? '' :  <div className={styles.resultBox}>
        <div className={styles.resultBox_title}>
          {translator["Статус заявки"][lang]}
        </div>
        {contextApplication.data?.is_approved !== null && (
          <div className={styles.finalResult}>
            <Icon
              size={120}
              name={
                contextApplication.data?.is_approved
                  ? "tickCircle"
                  : "closeFilled"
              }
              className={classNames(
                contextApplication.data?.is_approved ? styles.green : styles.red
              )}
            />
            <div
              className={classNames(
                styles.finalResultText,
                contextApplication.data?.is_approved ? styles.green : styles.red
              )}
            >
              {contextApplication.data?.selected_recommendation
                ? translator["Одобрено по рекомендации"][lang]
                : contextApplication.data?.is_approved
                ? translator["Одобрено"][lang]
                : translator["Отказано"][lang]}
            </div>
          </div>
        )}
        <div className={styles.resultBox_resultText}>
          <div className={styles.item}>
            <div className={styles.key}>
              {translator["Скоринг"][lang]}{" "}
              {selectedRecommendation
                ? `(${Math.round(selectedRecommendation.good_prob * 100)}%)`
                : (contextApplication.data?.scoring_log ||
                    contextApplication.data?.new_scoring_log) &&
                  `(${Math.round(
                    contextApplication.data?.scoring_log?.prediction ??
                      contextApplication.data?.new_scoring_log?.prediction ??
                      0
                  )}%)`}
              :
            </div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.scoring_log === null &&
                  contextApplication.data?.new_scoring_log === null
                  ? styles.grey
                  : contextApplication.data?.scoring_log?.is_approved ||
                    contextApplication.data?.new_scoring_log?.is_approved
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.new_scoring_log === null &&
              contextApplication.data?.scoring_log === null
                ? translator["Неизвестно"][lang]
                : contextApplication.data?.scoring_log?.is_approved ||
                  contextApplication.data?.new_scoring_log?.is_approved
                ? translator["Одобрено"][lang]
                : translator["Отказано"][lang]}
            </div>
          </div>
        </div>
        {contextApplication?.data &&
        !contextApplication?.data?.selected_recommendation &&
        contextApplication?.data.recommendations_log?.length > 0 ? (
          <Button color="violet" onClick={handleOpenRecommendation}>
            {translator["Рекомендации к заявке"][lang]}
          </Button>
        ) : contextApplication.data?.is_approved === false &&
          !contextApplication.data.selected_recommendation ? (
          <div className={styles.non_rec_text}>
            {translator["Рекомендаций нет"][lang]}
          </div>
        ) : (
          <></>
        )}
      </div>}
      <CSSTransition
        in={!!recommendationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal
          application={contextApplication.data}
          onClose={() => setRecommendationModal(false)}
        />
      </CSSTransition>
    </div>
  );
};

export default ResultBox;
