import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import axios from "axios";

import global from "global";
import transcript from "utils/transcript";
import prettierNumber from "utils/prettier-number";
import { useSelector } from "store/hooks";
import { TLang } from "store/models/lang";
import Loading from "components/loading";
import translator from "translator";
import styles from "./result.module.scss";
import ResultBox from "components/result-box";
import request from "request";

const token = "2ZTUCMlPTElYfKEGBUqZRtHE8H8fM1Wk";

const instance = axios.create({
  baseURL: "https://spitamen.westeurope.cloudapp.azure.com",
  headers: {
    "Content-Type": "application/json",
    "api-partner-token": `${token}`,
  },
});

function convertEducationType(type: string) {
  switch (type) {
    case "higher_education":
      return "Higher Education";
    case "incomplete_secondary_education":
      return "Incomplete Secondary Education";
    case "secondary_education":
      return "Secondary Education";
    case "secondary_with_specialisation":
      return "Secondary Education with Specialisation";
    case "other_education":
      return "Other Education";
    default:
      return "Unknown Education Type";
  }
}
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  result: {
    fontSize: "18px",
    fontWeight: 700,
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Result = () => {
  const contextApplication = useSelector((state) => state.application);
  const lang = useSelector((state) => state.lang);
  const navigate = useNavigate();
  const [shapLoading, setShapLoading] = React.useState(false);

  React.useEffect(() => {
    if (contextApplication.data?.is_approved === null) navigate("/");
  }, [contextApplication, navigate]);

  return (
    <div className={styles.result}>
      <div className={styles.content}>
        <div className={styles.pdf}>
          <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
            <Pdf contextApplication={contextApplication} lang={lang} />
          </PDFViewer>
        </div>
        <div className={styles.shap_value}>
          {shapLoading && (
            <div className={styles.loading_shap}>
              <Loading />
            </div>
          )}
          <div className={styles.shap_value_title}>
            {translator["Значения Шэпли для принятия решений"][lang]}
          </div>
          {contextApplication.data?.scoring_log && (
            <>
              <iframe
                title="shap_link"
                src={contextApplication.data.scoring_log?.shap_link}
                style={{ width: "100%" }}
                onLoad={() => setShapLoading(false)}
              ></iframe>
              <div className={styles.gpt_text}>
                {contextApplication.data?.gpt_text[lang]
                  ?.split("\n")
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      {i} <br />
                    </React.Fragment>
                  ))}
              </div>
            </>
          )}
          {contextApplication.data?.new_scoring_log && (
            <>
              <iframe
                title="shap_link"
                src={contextApplication.data.new_scoring_log?.shap_link}
                style={{ width: "100%" }}
                onLoad={() => setShapLoading(false)}
              ></iframe>
              <div className={styles.gpt_text}>
                {contextApplication.data?.gpt_text &&
                  contextApplication.data?.gpt_text[lang]
                    ?.split("\n")
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {i} <br />
                      </React.Fragment>
                    ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.resultBoxGroup}>
        <ResultBox />
      </div>
    </div>
  );
};

const Pdf = ({
  contextApplication,
  lang,
}: {
  contextApplication: global.contextApplication;
  lang: TLang;
}) => {
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();
  const selectedRecommendation = React.useMemo(() => {
    return contextApplication.data?.recommendations_log?.find(
      (rec) => rec.id === contextApplication.data?.selected_recommendation
    );
  }, [
    contextApplication.data?.selected_recommendation,
    contextApplication.data?.recommendations_log,
  ]);

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);
  // const [collectionData, setCollectionData] =
  //   React.useState<global.collecation_data>();
  const [tableData, setTableData] = React.useState<global.table_data>();
  const [pending, setPending] = React.useState(false);

  // useEffect(() => {
  //   if (!contextApplication.data?.scoring_log) return;
  //   setPending(true);
  //   instance
  //     .post("/scoring_regression", {
  //       age: Number(contextApplication.data?.scoring_log?.age),
  //       gender: contextApplication.data?.scoring_log?.gender === "male" ? 1 : 0,
  //       amount: Number(contextApplication.data?.amount),
  //       duration: Number(contextApplication.data?.duration),
  //       credit_history_count: Number(
  //         contextApplication.data?.scoring_log?.credit_history_count
  //       ),
  //       dependants: Number(contextApplication.data?.scoring_log?.dependants),
  //       int_rate: Number(contextApplication.data?.int_rate) / 100,
  //       work_exp: Number(contextApplication.data?.scoring_log?.work_exp),
  //       mon_income: Number(contextApplication.data?.scoring_log?.mon_income),
  //       sector:
  //         contextApplication.data?.scoring_log?.sector[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.sector.slice(1),
  //       marital_status:
  //         contextApplication.data?.scoring_log?.marital_status[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.marital_status.slice(1),
  //       phone_type:
  //         (contextApplication.data?.scoring_log?.phone_type === "ios" &&
  //           contextApplication.data?.scoring_log?.phone_type.toUpperCase()) ||
  //         contextApplication.data?.scoring_log?.phone_type[0].toUpperCase() +
  //           contextApplication.data?.scoring_log?.phone_type.slice(1),
  //       education: convertEducationType(
  //         contextApplication.data.scoring_log.education
  //       ),
  //       job_status:
  //         contextApplication.data?.scoring_log?.job_status[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.job_status.slice(1),
  //       work_place: "Private company",
  //       has_transport: contextApplication.data?.scoring_log?.has_transport,
  //       has_house:
  //         contextApplication.data?.scoring_log?.has_house[0].toUpperCase() +
  //         contextApplication.data?.scoring_log?.has_house.slice(1),
  //       threshold: contextApplication.data?.scoring_log?.threshold,
  //     })
  //     .then((res) => setCollectionData(res.data))
  //     .then((data) => localStorage.setItem("collection", JSON.stringify(data)))
  //     .finally(() => setPending(false));
  // }, [
  //   contextApplication.data?.amount,
  //   contextApplication.data?.duration,
  //   contextApplication.data?.int_rate,
  //   contextApplication.data?.scoring_log,
  // ]);
  useEffect(() => {
    if (!contextApplication.data?.scoring_log) return;
    setPending(true);
    instance
      .post("/scoring_survival", {
        age: Number(contextApplication.data?.scoring_log?.age),
        gender: contextApplication.data?.scoring_log?.gender === "male" ? 1 : 0,
        amount: Number(contextApplication.data?.amount),
        duration: Number(contextApplication.data?.duration),
        credit_history_count: Number(
          contextApplication.data?.scoring_log?.credit_history_count
        ),
        dependants: Number(contextApplication.data?.scoring_log?.dependants),
        int_rate: Number(contextApplication.data?.int_rate) / 100,
        work_exp: Number(contextApplication.data?.scoring_log?.work_exp),
        mon_income: Number(contextApplication.data?.scoring_log?.mon_income),
        sector:
          contextApplication.data?.scoring_log?.sector[0].toUpperCase() +
          contextApplication.data?.scoring_log?.sector.slice(1),
        marital_status:
          contextApplication.data?.scoring_log?.marital_status[0].toUpperCase() +
          contextApplication.data?.scoring_log?.marital_status.slice(1),
        phone_type:
          (contextApplication.data?.scoring_log?.phone_type === "ios" &&
            contextApplication.data?.scoring_log?.phone_type.toUpperCase()) ||
          contextApplication.data?.scoring_log?.phone_type[0].toUpperCase() +
            contextApplication.data?.scoring_log?.phone_type.slice(1),
        education: convertEducationType(
          contextApplication.data.scoring_log.education
        ),
        job_status:
          contextApplication.data?.scoring_log?.job_status[0].toUpperCase() +
          contextApplication.data?.scoring_log?.job_status.slice(1),
        work_place: "Private company",
        has_transport: contextApplication.data?.scoring_log?.has_transport,
        has_house:
          contextApplication.data?.scoring_log?.has_house[0].toUpperCase() +
          contextApplication.data?.scoring_log?.has_house.slice(1),
        threshold: contextApplication.data?.scoring_log?.threshold,
      })
      .then((res) => setTableData(res.data))
      .finally(() => setPending(false));
  }, [
    contextApplication.data?.amount,
    contextApplication.data?.duration,
    contextApplication.data?.int_rate,
    contextApplication.data?.scoring_log,
  ]);
  // function convert(str?: string) {
  //   if (!str) return;
  //   const number = Number(str.match(/\d+(\.\d+)?/g));
  //   let text = "";
  //   const suffixes = ["th", "st", "nd", "rd"];
  //   // Special case for 11, 12, and 13
  //   if (number % 100 >= 11 && number % 100 <= 13) {
  //     text = suffixes[0];
  //   }
  //   // Use the last digit to determine the suffix
  //   const lastDigit = number % 10;
  //   text = suffixes[lastDigit <= 3 ? lastDigit : 0];
  //   return number.toString() + text;
  // }
  return (
    <Document>
      {/* Скоринг */}
      {contextApplication.data?.scoring_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>{translator["Результат скоринга"][lang]}</Text>
          </View>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.text}>
              {translator["Дата создания"][lang]}:{" "}
              {new Date(
                contextApplication.data?.created_at ?? ""
              ).toLocaleString()}
            </Text>
            <Text style={pdfStyles.text}>
              {translator["ID заявки"][lang]}: {contextApplication.data?.id}
            </Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Сумма"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{Number(contextApplication.data.amount)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Длительность"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.duration}{" "}
                    {translator["мес."][lang]}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Процентная ставка"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.int_rate.toFixed(2)} %</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Кредитная история"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.credit_history_count}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Поручитель/Созаемщик"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.has_guarantor.getKeyFromValue(
                      contextApplication.data.scoring_log.has_guarantor ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Залог"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.has_collateral.getKeyFromValue(
                      contextApplication.data.scoring_log.has_collateral ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Ежемесячный доход"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {prettierNumber(
                      contextApplication.data?.scoring_log?.mon_income ?? 0
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Цель кредита"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.sector.getKeyFromValue(
                      contextApplication.data?.scoring_log?.sector
                    )}
                  </Text>
                </View>
              </View>

              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {translator["Кредит во время экстренного периода"][lang]}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.is_disaster.getKeyFromValue(
                      contextApplication.data?.scoring_log?.is_disaster
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Статус деятельности"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.job_status.getKeyFromValue(
                      contextApplication.data.scoring_log.job_status
                    )}
                  </Text>
                </View>
              </View>

              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Образование"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.educationEng.getValueFromKey(
                      contextApplication.data?.scoring_log?.education ?? ""
                    )}
                  </Text>
                </View>
              </View>

              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Наличие автомобиля"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.isCarСode.getKeyFromValue(
                      contextApplication.data?.scoring_log?.has_transport ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Наличие жилья"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.isHomeСode.getKeyFromValue(
                      contextApplication.data?.scoring_log?.has_house ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Возраст"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.age}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Пол"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.gender.getKeyFromValue(
                      contextApplication.data?.scoring_log?.gender ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Семейное положение"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.marital_status.getKeyFromValue(
                      contextApplication.data?.scoring_log?.marital_status ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Город"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.district.getKeyFromValue(
                      contextApplication.data?.scoring_log.district
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Количество иждивиенцев"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.dependants}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Опыт работы в годах"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.work_exp ?? ""}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Сфера занятости"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.work_place.getKeyFromValue(
                      contextApplication.data?.scoring_log?.work_place
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Наличие телефона"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.phone_type.getKeyFromValue(
                      contextApplication.data?.scoring_log?.phone_type
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Оценка БКИТ"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                  {transcript.bkit_score.getKeyFromValue(
                      contextApplication.data?.scoring_log?.bkit_score
                    )}
                  </Text>
                </View>
              </View>
              {String(contextApplication.data.scoring_log.phone_number) !==
                "+992" && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>{translator["Номер телефона клиента"][lang]}</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {contextApplication.data.scoring_log.phone_number}
                      </Text>
                    </View>
                  </View>
                )}
              {contextApplication.data.scoring_log.email !== "" && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>{translator["Электронная почта клиента"][lang]}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data.scoring_log.email}</Text>
                  </View>
                </View>
              )}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Кредитный эксперт"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.created_by.login}</Text>
                </View>
              </View>
            </View>
            <View style={pdfStyles.bottom}>
              <Text style={pdfStyles.result}>
                {translator["Результат"][lang]}:{" "}
                {contextApplication.data?.scoring_log.is_approved
                  ? translator["Одобрено"][lang]
                  : translator["Отказано"][lang]}{" "}
                {contextApplication.data?.scoring_log?.prediction}%
              </Text>
              <Text style={pdfStyles.result}>
                {translator["Порог отказа"][lang]}:{" "}
                {Number(contextApplication.data?.scoring_log?.threshold) * 100}%
              </Text>
            </View>
          </View>
        </Page>
      )}
      {contextApplication.data?.new_scoring_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>{translator["Результат скоринга"][lang]}</Text>
          </View>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.text}>
              {translator["Дата создания"][lang]}:{" "}
              {new Date(
                contextApplication.data?.created_at ?? ""
              ).toLocaleString()}
            </Text>
            <Text style={pdfStyles.text}>
              {translator["ID заявки"][lang]}: {contextApplication.data?.id}
            </Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Сумма"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {Number(
                      selectedRecommendation?.amount ??
                        contextApplication.data.amount
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Длительность"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {selectedRecommendation?.duration ??
                      contextApplication.data.duration}{" "}
                    {translator["мес."][lang]}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Процентная ставка"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.int_rate} %</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Возраст"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.new_scoring_log.age}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Количество иждивиенцев"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.new_scoring_log.dependents}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Цель кредита"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.sector.getKeyFromValue(
                      contextApplication.data.new_scoring_log.sector ?? ""
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Пол"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.gender.getKeyFromValue(
                      contextApplication.data.new_scoring_log.gender
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Семейное положение"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.marital_status.getKeyFromValue(
                      contextApplication.data.new_scoring_log.marital_status
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Образование"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.education.getKeyFromValue(
                      contextApplication.data.new_scoring_log.education
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Город/Сельская местность"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.city_rural.getKeyFromValue(
                      contextApplication.data.new_scoring_log.city_rural
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {translator["Кредит во время экстренного периода"][lang]}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {/* {transcript.is_disaster.getKeyFromValue(
                    contextApplication.data?.scoring_log?.is_disaster
                  )} */}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{translator["Кредитный эксперт"][lang]}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.created_by.login}</Text>
                </View>
              </View>
            </View>
            <View style={pdfStyles.bottom}>
              <Text style={pdfStyles.result}>
                {translator["Результат"][lang]}:{" "}
                {contextApplication.data?.new_scoring_log.is_approved
                  ? translator["Одобрено"][lang]
                  : translator["Отказано"][lang]}{" "}
                {selectedRecommendation
                  ? Math.round(selectedRecommendation?.good_prob * 100)
                  : contextApplication.data?.new_scoring_log?.prediction}
                %
              </Text>
              <Text style={pdfStyles.result}>
                {translator["Порог отказа"][lang]}:{" "}
                {Number(contextApplication.data?.new_scoring_log?.threshold) *
                  100}
                %
              </Text>
            </View>
          </View>
        </Page>
      )}
      {/* OCR */}
      {contextApplication.data?.ocr_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>{translator["Результат OCR"][lang]}</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Общий доход"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.overall_income ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Доход за последние 6 месяцев."][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.income_6_months ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  {translator["Средний доход за последние 6 месяцев."][lang]}
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.average_income_6_months ??
                      0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  {translator["Доход клиента в кредит (60% от дохода)"][lang]}
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.income_for_credit ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Текущий мес. платеж"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.current_monthly_payment ??
                      0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  {
                    translator["Предполагаемая кредитоспособность клиента"][
                      lang
                    ]
                  }
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log
                      ?.current_creditworthiness ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Предполагаемый платеж"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {prettierNumber(
                    contextApplication.data?.ocr_log?.estimated_payment ?? 0
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Эксперт"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data.created_by.login}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Результат"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.ocr_log?.is_approved
                    ? translator["Одобрено"][lang]
                    : translator["Отказано"][lang]}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
      {/* Макроданные */}
      {contextApplication.data?.scoring_log && (
        <Page style={pdfStyles.page}>
          <Text style={pdfStyles.heading}>
            {translator["Макроданные"][lang]}
          </Text>
          <Text style={pdfStyles.text}>
            {translator["Дата создания"][lang]}:{" "}
            {new Date(
              contextApplication.data?.created_at ?? ""
            ).toLocaleString()}
          </Text>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  {translator["Инфляция на продовольственные товары"][lang]}
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.food_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  {translator["Инфляция непотребительских товаров"][lang]}
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.nonfood_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Инфляция услуг"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.services_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Курс доллара"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.exrate_usd_avg}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Средний доход населения"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.services_inflation}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["ВВП"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.gdp}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Индекс потребительских цен"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.cpi}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Цена на нефть марки Брент"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{macrodata?.brent_oil_price}</Text>
              </View>
            </View>
          </View>
        </Page>
      )}
      {contextApplication.data?.is_approved  && (
        <Page style={pdfStyles.page}>
          <Text style={pdfStyles.heading}>
            {translator["Результат прогноза просрочки"][lang]}
          </Text>
          <Text style={pdfStyles.text}>
            {translator["Дата создания"][lang]}:{" "}
            {new Date(
              contextApplication.data?.created_at ?? ""
            ).toLocaleString()}
          </Text>
          {pending ? <Loading/> : 
          <View style={pdfStyles.table}>
          <View style={pdfStyles.tr}>
            <View style={pdfStyles.td}>
              <Text>{translator["Месяц"][lang]}</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{translator["Вероятность выплоты"][lang]}</Text>
            </View>
            <View style={pdfStyles.td}>
              <Text>{translator["Просрочку"][lang]}</Text>
            </View>
          </View>
          {tableData?.prediction_results &&
            Object.entries(tableData.prediction_results).map(
              ([key, value]) => (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>{parseInt(key)}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{Number(value[0] * 100).toFixed(2)}%</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {value.delay
                        ? translator["Да"][lang]
                        : translator["Нет кредит"][lang]}
                    </Text>
                  </View>
                </View>
              )
            )}

          {/* <Text style={pdfStyles.result}>
          {lang === "ru"
            ? collectionData?.result
            : `The client probably does the stitching in the ${convert(
                collectionData?.result
              )}  month.`}
          </Text> */}
        </View>
          }
        </Page>
      )}
      {contextApplication.data?.new_scoring_log && (
        <Page style={pdfStyles.page}>
          <Text style={pdfStyles.heading}>
            {translator["Макроданные"][lang]}{" "}
            {new Date(
              contextApplication.data?.created_at ?? ""
            ).toLocaleDateString()}
          </Text>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  {translator["Инфляция на продовольственные товары"][lang]}
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.new_scoring_log?.food_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>
                  {translator["Инфляция непотребительских товаров"][lang]}
                </Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.new_scoring_log?.nonfood_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Инфляция услуг"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.new_scoring_log?.services_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Курс доллара"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.new_scoring_log?.exrate_usd_avg}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Средний доход населения"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.new_scoring_log?.services_inflation}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["ВВП"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.new_scoring_log?.gdp}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>{translator["Цена на нефть марки Брент"][lang]}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.new_scoring_log?.brent_oil_price}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
