import React from "react";

import styles from "./login-pan.module.scss";
import loginPan from "./login-pan";
import Input from "components/input";

const LoginPanel: React.FC<loginPan.props> = ({ username, setUsername, password, setPassword, onSubmit }) => {
  return (
    <form
      className={styles.loginPan}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <div className={styles.inputs}>
        <Input title="Username" type="string" value={username} onInput={(value) => setUsername(value)} />
        <Input title="Password" type="password" value={password} onInput={(value) => setPassword(value)} />
      </div>
      <button type="submit" className={styles.submit}>
        <span className={styles.submitText}>Login</span>
      </button>
    </form>
  );
};

export default LoginPanel;
