import { createModel } from "@rematch/core";

export type TLang = "ru" | "en";

const lang = createModel()({
  state: "en" as TLang,
  reducers: {
    SET_LANG(_, payload: TLang) {
      return payload;
    },
  },
});

export default lang;
