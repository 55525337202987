const translator = {
  "ID клиента должна иметь 12 цифр": {
    ru: "ID клиента должна иметь 12 цифр",
    en: "Client ID must contain 12 digits",
  },
  "Сумма обязательна": {
    ru: "Сумма обязательна",
    en: "Amount is required",
  },
  "Цель кредита": {
    ru: "Цель кредита",
    en: "Type of loan",
  },
  "Онлайн деньги": {
    ru: "Онлайн деньги",
    en: "Online Cash",
  },
  Бизнес: {
    ru: "Бизнес",
    en: "Business",
  },
  Cегмент: {
    ru: "Cегмент",
    en: "Agriculture",
  },
  Краткосрочный: {
    ru: "Краткосрочный",
    en: "Short-term",
  },
  Среднесрочный: {
    ru: "Среднесрочный",
    en: "Medium-term",
  },
  "Рекомендации к заявке": {
    ru: "Рекомендации к заявке",
    en: "Recommendations for the credit",
  },
  "Рекомендаций нет": {
    ru: "Рекомендаций нет",
    en: "No recommendations",
  },
  "Одобрено по рекомендации": {
    ru: "Одобрено по рекомендации",
    en: "Approved on the recommendation of",
  },
  "Выбранная рекомендация": {
    ru: "Выбранная рекомендация",
    en: "Selected recommendation",
  },
  Рекомендации: {
    ru: "Рекомендации",
    en: "Recommendations",
  },
  Рекомендация: {
    ru: "Рекомендация",
    en: "Recommendation",
  },
  "Вероятность выплаты": {
    ru: "Вероятность выплаты",
    en: "Good prediction",
  },
  Подтверить: {
    ru: "Подтверить",
    en: "Submit",
  },
  "Ежемесячный платеж": {
    ru: "Ежемесячный платеж",
    en: "Monthly payment",
  },
  "Процентная ставка обязательна": {
    ru: "Процентная ставка обязательна",
    en: "Interest rate is required",
  },
  "Длительность кредита обязательна": {
    ru: "Длительность кредита обязательна",
    en: "Duration is required",
  },
  Поиск: {
    ru: "Поиск",
    en: "Search",
  },
  "Инфляция на продовольственные товары": {
    ru: "Инфляция на продовольственные товары",
    en: "Food inflation",
  },
  "Инфляция непотребительских товаров": {
    ru: "Инфляция непродовольственных товаров",
    en: "Non-food inflation",
  },
  "Инфляция услуг": {
    ru: "Инфляция услуг",
    en: "Service inflation",
  },
  "Курс доллара": {
    ru: "Курс доллара",
    en: "USD rate",
  },
  "Значения Шэпли для принятия решений": {
    ru: "Значения Шэпли для принятия решений",
    en: "Decision Shapley Values",
  },
  "Средний доход населения": {
    ru: "Средний доход",
    en: "Average income",
  },
  ВВП: {
    ru: "ВВП",
    en: "GDP",
  },
  "Цена на нефть марки Брент": {
    ru: "Цена на нефть марки Брент",
    en: "Brent oil price",
  },
  Настройки: {
    ru: "Настройки",
    en: "Settings",
  },
  "Список пользователей": {
    ru: "Список пользователей",
    en: "User list",
  },
  "Общий доход": {
    ru: "Общий доход",
    en: "Total income",
  },
  "Доход за последние 6 месяцев.": {
    ru: "Доход за последние 6 месяцев.",
    en: "Income for the last 6 mon.",
  },
  "Средний доход за последние 6 месяцев.": {
    ru: "Средний доход за последние 6 месяцев.",
    en: "Average income for the last 6 mon.",
  },
  "Доход клиента в кредит (60% от дохода)": {
    ru: "Доход клиента в кредит (60% от дохода)",
    en: "Customer income for credit (60% of income)",
  },
  "Текущий мес. платеж": {
    ru: "Текущий мес. платеж",
    en: "Current mon. payment",
  },
  "Предполагаемая кредитоспособность клиента": {
    ru: "Предполагаемая кредитоспособность клиента",
    en: "Assumed creditworthiness of the client",
  },
  "Предполагаемый платеж": {
    ru: "Предполагаемый платеж",
    en: "Estimated payment",
  },
  Эксперт: {
    ru: "Эксперт",
    en: "Expert",
  },
  Выйти: {
    ru: "Выйти",
    en: "Logout",
  },
  Язык: {
    ru: "Язык",
    en: "Language",
  },
  Русский: {
    ru: "Русский",
    en: "Russian",
  },
  Английский: {
    ru: "Английский",
    en: "English",
  },
  Сохранить: {
    ru: "Сохранить",
    en: "Save",
  },
  От: {
    ru: "От",
    en: "From",
  },
  До: {
    ru: "До",
    en: "To",
  },
  Фильтр: {
    ru: "Фильтр",
    en: "Filter",
  },
  Очистить: {
    ru: "Очистить",
    en: "Clear",
  },
  "Экспортировать в .XLSX": {
    ru: "Экспортировать в .XLSX",
    en: "Export .XLSX file",
  },
  "Новая заявка": {
    ru: "Новая заявка",
    en: "New application",
  },
  "ID заявки": {
    ru: "ID заявки",
    en: "Application ID",
  },
  "Кредитный эксперт": {
    ru: "Кредитный эксперт",
    en: "Expert",
  },
  "Дата создания": {
    ru: "Дата создания",
    en: "Create date",
  },
  "ID клиента": {
    ru: "ID клиента",
    en: "Client ID",
  },
  Сумма: {
    ru: "Сумма",
    en: "Amount",
  },
  "Ежемесячный доход": {
    ru: "Ежемесячный доход",
    en: "Monthly income",
  },
  Длительность: {
    ru: "Длительность",
    en: "Duration",
  },
  "Процентная ставка": {
    ru: "Процентная ставка",
    en: "Interest rate",
  },
  Животноводство: {
    ru: "Животноводство",
    en: "Livestock",
  },
  Строительство: {
    ru: "Строительство",
    en: "Construction",
  },
  Водитель: {
    ru: "Водитель",
    en: "Driver",
  },
  Работодатель: {
    ru: "Работодатель",
    en: "Employer",
  },
  Livestock: {
    ru: "Livestock",
    en: "Individual entrepreneur",
  },
  Домохозяйка: {
    ru: "Домохозяйка",
    en: "Housewife",
  },
  Медицина: {
    ru: "Медицина",
    en: "Medicine",
  },
  Военные: {
    ru: "Военные",
    en: "Military",
  },
  "Государственный служащий": {
    ru: "Государственный служащий",
    en: "Civil servant",
  },
  "В отставке": {
    ru: "В отставке",
    en: "Retired",
  },
  Трудоустройство: {
    ru: "Трудоустройство",
    en: "Employment",
  },
  Результат: {
    ru: "Результат",
    en: "Result",
  },
  "Прогноз просрочки": {
    ru: "Прогноз просрочки",
    en: "Survival scoring",
  },
  Скоринг: {
    ru: "Скоринг",
    en: "Scoring",
  },
  Отречься: {
    ru: "Отречься",
    en: "Abjure",
  },
  Агонист: {
    ru: "Агонист",
    en: "Agonistic",
  },
  Авиакомпания: {
    ru: "Авиакомпания",
    en: "Airline",
  },
  Бандит: {
    ru: "Бандит",
    en: "Bandit",
  },
  Банкет: {
    ru: "Банкет",
    en: "Banquet",
  },
  Бинокль: {
    ru: "Бинокль",
    en: "Binoculars",
  },
  Биолог: {
    ru: "Биолог",
    en: "Biologist",
  },
  Доска: {
    ru: "Доска",
    en: "Blackboard",
  },
  Набережный: {
    ru: "Набережный",
    en: "Board",
  },
  Ирис: {
    ru: "Ирис",
    en: "Butterscotch",
  },
  Камера: {
    ru: "Камера",
    en: "Camera",
  },
  Наушники: {
    ru: "Наушники",
    en: "Headphone",
  },
  Кампус: {
    ru: "Кампус",
    en: "Campus",
  },
  Сом: {
    ru: "Сом",
    en: "Catfish",
  },
  Карсик: {
    ru: "Карсик",
    en: "Carsick",
  },
  Праздник: {
    ru: "Праздник",
    en: "Celebrate",
  },
  Сельдерей: {
    ru: "Сельдерей",
    en: "Celery",
  },
  Гражданин: {
    ru: "Гражданин",
    en: "Citizen",
  },
  Раскраска: {
    ru: "Раскраска",
    en: "Coloring",
  },
  Компактный: {
    ru: "Компактный",
    en: "Compact",
  },
  Двигатель: {
    ru: "Двигатель",
    en: "Engine",
  },
  Темный: {
    ru: "Темный",
    en: "Dark",
  },
  Повреждения: {
    ru: "Повреждения",
    en: "Damage",
  },
  Опасные: {
    ru: "Опасные",
    en: "Dangerous",
  },
  Декорум: {
    ru: "Декорум",
    en: "Decorum",
  },
  Одобрить: {
    ru: "Одобрить",
    en: "Endorse",
  },
  Привлекать: {
    ru: "Привлекать",
    en: "Engender",
  },
  Erratic: {
    ru: "Erratic",
    en: "Erratic",
  },
  Конверт: {
    ru: "Конверт",
    en: "Envelope",
  },
  Этимология: {
    ru: "Этимология",
    en: "Etymology",
  },
  Очевидец: {
    ru: "Очевидец",
    en: "Eyewitness",
  },
  Восхваление: {
    ru: "Восхваление",
    en: "Eulogy",
  },
  Рыба: {
    ru: "Рыба",
    en: "Fish",
  },
  Еда: {
    ru: "Еда",
    en: "Food",
  },
  Foreclose: {
    ru: "Foreclose",
    en: "Foreclose",
  },
  Телефон: {
    ru: "Телефон",
    en: "Phone",
  },
  Преступник: {
    ru: "Преступник",
    en: "Perpetrator",
  },
  Парентеза: {
    ru: "Парентеза",
    en: "Parenthesis",
  },
  Сова: {
    ru: "Сова",
    en: "Owl",
  },
  Шумный: {
    ru: "Шумный",
    en: "Noisome",
  },
  Новорожденный: {
    ru: "Новорожденный",
    en: "Newborn",
  },
  "Книжный червь": {
    ru: "Книжный червь",
    en: "Bookworm",
  },
  "Нет записей": {
    ru: "Нет записей",
    en: "No applications",
  },
  "Вероятность одобрения": {
    ru: "Вероятность одобрения",
    en: "Probability of approval",
  },
  Создать: {
    ru: "Создать",
    en: "Create",
  },
  Отмена: {
    ru: "Отмена",
    en: "Cancel",
  },
  "Новый пользователь": {
    ru: "Новый пользователь",
    en: "New user",
  },
  Логин: {
    ru: "Логин",
    en: "Login",
  },
  Роль: {
    ru: "Роль",
    en: "Role",
  },
  Статус: {
    ru: "Статус",
    en: "Status",
  },
  Активный: {
    ru: "Активный",
    en: "Active",
  },
  Заблокирован: {
    ru: "Заблокирован",
    en: "Blocked",
  },
  Разблокирован: {
    ru: "Разблокирован",
    en: "Unblocked",
  },
  Админ: {
    ru: "Админ",
    en: "Admin",
  },
  Изменить: {
    ru: "Изменить",
    en: "Edit",
  },
  Заблокировать: {
    ru: "Заблокировать",
    en: "Block",
  },
  Разблокировать: {
    ru: "Разблокировать",
    en: "Unblock",
  },
  "Редактирование пользователя": {
    ru: "Редактирование пользователя",
    en: "Edit user",
  },
  Пароль: {
    ru: "Пароль",
    en: "Password",
  },
  "Подтверите пароль": {
    ru: "Подтверите пароль",
    en: "Confirm password",
  },
  "Кредитные данные": {
    ru: "Кредитные данные",
    en: "Credit data",
  },
  "Результат OCR": {
    ru: "Результат OCR",
    en: "OCR decision",
  },
  "Результат скоринга": {
    ru: "Результат скоринга",
    en: "Scoring decision",
  },
  Черновик: {
    ru: "Черновик",
    en: "Blank",
  },
  "Одобрено рекомендацией": {
    ru: "Одобрено рекомендацией",
    en: "Approved by the recommendation",
  },
  Неизвестно: {
    ru: "Неизвестно",
    en: "Unknown",
  },
  Одобрено: {
    ru: "Одобрено",
    en: "Approved",
  },
  Отказано: {
    ru: "Отказано",
    en: "Rejected",
  },
  "Закрепленные файлы": {
    ru: "Закрепленные файлы",
    en: "Attachments",
  },
  "Нет файлов": {
    ru: "Нет файлов",
    en: "No attachments",
  },
  "Порог отказа": {
    ru: "Порог отказа",
    en: "Threshold",
  },
  "Вид кредита": {
    ru: "Вид кредита",
    en: "Type of credit",
  },
  Филиал: {
    ru: "Филиал",
    en: "Branch/Point",
  },
  Ашт: {
    ru: "Ашт",
    en: "Asht",
  },
  Айни: {
    ru: "Айни",
    en: "Ayni",
  },
  "Б.гафуров": {
    ru: "Б.Гафуров",
    en: "B.Gafurov",
  },
  Бальджуван: {
    ru: "Бальджуван",
    en: "Baljuvon",
  },
  Балхи: {
    ru: "Балхи",
    en: "Balkhi",
  },
  Туркестан: {
    ru: "Туркестан",
    en: "Turkistan",
  },
  Павлодар: {
    ru: "Павлодар",
    en: "Pavlodar",
  },
  Талдыкорган: {
    ru: "Талдыкорган",
    en: "Taldykorgan",
  },
  Жамбыл: {
    ru: "Жамбыл",
    en: "Jambyl",
  },
  Костанай: {
    ru: "Костанай",
    en: "Kostanay",
  },
  Акмолинская: {
    ru: "Акмолинская",
    en: "Akmola",
  },
  Семей: {
    ru: "Семей",
    en: "Semei",
  },
  Астана: {
    ru: "Астана",
    en: "Astana",
  },
  "Восточно-Казахстанская": {
    ru: "Восточно-Казахстанская",
    en: "East Kazakhstan",
  },
  Актобе: {
    ru: "Актобе",
    en: "Aktobe",
  },
  Караганда: {
    ru: "Караганда",
    en: "Karaganda",
  },
  "Северо-Казахстанская": {
    ru: "Северо-Казахстанская",
    en: "North Kazakhstan",
  },
  "Западно-Казахстанская": {
    ru: "Западно-Казахстанская",
    en: "West Kazakhstan",
  },
  "Город/Сельская местность": {
    ru: "Город/Сельская местность",
    en: "City of residence",
  },
  Город: {
    ru: "Город",
    en: "City",
  },
  "Сельская местность": {
    ru: "Сельская местность",
    en: "Rural",
  },
  Район: {
    ru: "Город",
    en: "District",
  },
  "Количество прошлых кредитов": {
    ru: "Количество прошлых кредитов",
    en: "Number of credit histories",
  },
  "Тип клиента": {
    ru: "Тип клиента",
    en: "Client type",
  },
  "Процентные кредиты": {
    ru: "Процентные кредиты",
    en: "Interest rate loans",
  },
  "Тип кредита": {
    ru: "Тип кредита",
    en: "Credit type",
  },
  Макроданные: {
    ru: "Макроданные",
    en: "Macro data",
  },
  "Данные клиента": {
    ru: "Данные клиента",
    en: "Customer data",
  },
  Возраст: {
    ru: "Возраст",
    en: "Age",
  },
  Образование: {
    ru: "Образование",
    en: "Education",
  },
  Пол: {
    ru: "Пол",
    en: "Gender",
  },
  "Семейное положение": {
    ru: "Семейное положение",
    en: "Marital status",
  },
  Доход: {
    ru: "Доход",
    en: "Income",
  },
  "Количество иждивиенцев": {
    ru: "Количество иждивиенцев",
    en: "Number of dependents",
  },
  "Кредитная история": {
    ru: "Количество исторических кредитов",
    en: "Number of historical loans",
  },
  "чел.": {
    ru: "чел.",
    en: "",
  },
  "Опыт работы": {
    ru: "Опыт работы",
    en: "Work experience",
  },
  "Наличие машины": {
    ru: "Наличие машины",
    en: "Availability of a car",
  },
  "Наличие дома": {
    ru: "Наличие дома",
    en: "Availability of housing",
  },
  Созаёмщик: {
    ru: "Созаёмщик",
    en: "Co-borrower",
  },
  Назад: {
    ru: "Назад",
    en: "Back",
  },
  "мес.": {
    ru: "мес.",
    en: "mon.",
  },
  "Загрузить PDF документ": {
    ru: "Загрузить PDF документ",
    en: "Upload a .pdf document",
  },
  "Запустить OCR": {
    ru: "Запустить OCR",
    en: "Start OCR",
  },
  "Запустить скоринг": {
    ru: "Запустить скоринг",
    en: "Start scoring",
  },
  "Дата/время": {
    ru: "Дата/время",
    en: "Date/time",
  },
  "Сельское хозяйство": {
    ru: "Сельское хозяйство",
    en: "Agriculture",
  },
  Предпринимательский: {
    ru: "Предпринимательский",
    en: "Entrepreneurial",
  },
  Потребительский: {
    ru: "Потребительский",
    en: "Consumer",
  },
  Новый: {
    ru: "Новый",
    en: "New",
  },
  Повторный: {
    ru: "Повторный",
    en: "Repeat",
  },
  "Высшее образование": {
    ru: "Высшее образование",
    en: "Higher education",
  },
  "Среднее образование": {
    ru: "Среднее образование",
    en: "Secondary education",
  },
  "Среднее специальное образование": {
    ru: "Среднее специальное образование",
    en: "Secondary special education",
  },
  "Неполное среднее образование": {
    ru: "Неполное среднее образование",
    en: "Incomplete secondary education",
  },
  "Другое образование": {
    ru: "Другое образование",
    en: "Other education",
  },
  "Статус заявки": {
    ru: "Статус заявки",
    en: "Application status",
  },
  Мужской: {
    ru: "Мужской",
    en: "Male",
  },
  Женский: {
    ru: "Женский",
    en: "Female",
  },
  Да: {
    ru: "Да",
    en: "Yes",
  },
  Нет: {
    ru: "Отсутствует",
    en: "No",
  },
  "Нет кредит": {
    ru: "Нет",
    en: "No",
  },
  "Нет телефон": {
    ru: "Нет",
    en: "No phone",
  },
  "Разведён/Разведена": {
    ru: "Разведён/Разведена",
    en: "Divorced",
  },
  "Женат/Замужем": {
    ru: "Женат/Замужем",
    en: "Married",
  },
  "Не женат/Не замужем": {
    ru: "Холостой",
    en: "Single",
  },
  "Вдовец/Вдова": {
    ru: "Вдовец/Вдова",
    en: "Widow/Widower",
  },
  "От 1 до 3 лет": {
    ru: "От 1 до 3 лет",
    en: "From 1 to 3 years",
  },
  "От 3 до 5 лет": {
    ru: "От 3 до 5 лет",
    en: "From 3 years to 5 years",
  },
  "Более 5 лет": {
    ru: "Более 5 лет",
    en: "more than 5 years",
  },
  "Нет опыта": {
    ru: "Нет опыта",
    en: "no experience",
  },
  "Клиент, вероятно, делает просрочку на 6-ом месяце": {
    ru: "Клиент, вероятно, делает просрочку на 6-ом месяце",
    en: "The client is probably making a 6th month delinquency",
  },
  Месяц: {
    ru: "Месяц",
    en: "Month",
  },
  "Вероятность выплоты": {
    ru: "Вероятность выплоты",
    en: "Probability of payments",
  },
  Просрочку: {
    ru: "Просрочка",
    en: "Overdue",
  },
  "Cтатус коллекции": {
    ru: "Cтатус коллекции",
    en: "Collection status",
  },
  "Результат прогноза просрочки": {
    ru: "Результат прогноза просрочки",
    en: "Survival scoring result",
  },
  Бохтар: {
    ru: "Бохтар",
    en: "Bokhtar",
  },
  Бустон: {
    ru: "Бустон",
    en: "Buston",
  },
  Чирик: {
    ru: "Чирик",
    en: "Chirik",
  },
  Дангара: {
    ru: "Дангара",
    en: "Dangara",
  },
  Дарвоз: {
    ru: "Дарвоз",
    en: "Darvoz",
  },
  Деваштич: {
    ru: "Деваштич",
    en: "Devashtich",
  },
  Душанбе: {
    ru: "Душанбе",
    en: "Dushanbe",
  },
  Дусти: {
    ru: "Дусти",
    en: "Dusti",
  },
  Фархор: {
    ru: "Фархор",
    en: "Farhor",
  },
  Фараван: {
    ru: "Фараван",
    en: "Farovon",
  },
  Файзабад: {
    ru: "Файзабад",
    en: "Fayzobod",
  },
  Фирдавси: {
    ru: "Фирдавси",
    en: "Firdavsi",
  },
  Худжанд: {
    ru: "Худжанд",
    en: "Khujand",
  },
  Гулистан: {
    ru: "Гулистан",
    en: "Guliston",
  },
  Хамадони: {
    ru: "Хамадони",
    en: "Hamadoni",
  },
  Хиссар: {
    ru: "Гиссар",
    en: "Hissor",
  },
  Исфара: {
    ru: "Исфара",
    en: "Isfara",
  },
  Истаравшан: {
    ru: "Истаравшан",
    en: "Istaravshan",
  },
  Истикляль: {
    ru: "Истикляль",
    en: "Istiqlol",
  },
  "Дж.Расулов": {
    ru: "Дж.Расулов",
    en: "J.Rasulov",
  },
  Джайхун: {
    ru: "Джайхун",
    en: "jayhun",
  },
  Джоми: {
    ru: "Джоми",
    en: "Jomi",
  },
  Кангурт: {
    ru: "Кангурт",
    en: "Kangurt",
  },
  Хоруг: {
    ru: "Хоруг",
    en: "khorug",
  },
  Хавалинг: {
    ru: "Хавалинг",
    en: "Khovaling",
  },
  Хуросан: {
    ru: "Хуросан",
    en: "Khuroson",
  },
  Конибодом: {
    ru: "Конибодом",
    en: "Konibodom",
  },
  Кубодян: {
    ru: "Кубодян",
    en: "Kubodiyon",
  },
  Мастчох: {
    ru: "Мастчох",
    en: "Mastchoh",
  },
  Куляб: {
    ru: "Куляб",
    en: "Kulob",
  },
  Муминабад: {
    ru: "Муминабад",
    en: "Muminobad",
  },
  Норак: {
    ru: "Норак",
    en: "Norak",
  },
  Нуробод: {
    ru: "Нуробод",
    en: "Nurobod",
  },
  Панч: {
    ru: "Панч",
    en: "Panj",
  },
  Панджакет: {
    ru: "Панджакент",
    en: "Panjakent",
  },
  Рашт: {
    ru: "Рашт",
    en: "Rasht",
  },
  Турсунзаде: {
    ru: "Турсунзаде",
    en: "Tursunzoda",
  },
  Вахдат: {
    ru: "Вахдат",
    en: "Vahdat",
  },
  Вахш: {
    ru: "Вахш",
    en: "Vakhsh",
  },
  Вандж: {
    ru: "Вандж",
    en: "Vanj",
  },
  Варзоб: {
    ru: "Варзоб",
    en: "Varzob",
  },
  Восе: {
    ru: "Восе",
    en: "Voce",
  },
  Рогун: {
    ru: "Рогун",
    en: "Rogun",
  },
  Мургоб: {
    ru: "Мургоб",
    en: "Murgob",
  },
  Рудаки: {
    ru: "Рудаки",
    en: "Rudaki",
  },

  Cангтуда: {
    ru: "Cангтуда",
    en: "Sangtuda",
  },
  Сангвор: {
    ru: "Сангвор",
    en: "Sangvor",
  },
  Шахринав: {
    ru: "Шахринав",
    en: "Shahrinav",
  },
  Шахритуз: {
    ru: "Шахритуз",
    en: "Shahrituz",
  },
  Темурмалик: {
    ru: "Темурмалик",
    en: "Temurmalik",
  },
  Таджикабад: {
    ru: "Таджикабад",
    en: "Tojikobod",
  },
  Кушониен: {
    ru: "Кушониен",
    en: "Kushaniyon",
  },

  //      "kushaniyon" "levacant" "mastchoh"   "nasiri_khusrav"   "рошткала"  "рушон" "сангтуда" "сангвор" "шахринав" "шахристан" "шахритуз" "шамсиддин_шахин" "шугнон" "сомон" "спитамен" "табашар" "темурмалик" "Таджикабад" "
  //  "джавон" "зафарабад"
  //       "kushoniyon" "levakant" "mastchoh"   "nosiri_khusrav"  "roshtkala"  "rushon" "sangtuda" "sangvor" "shahrinav" "shahriston" "shahrituz" "shamsiddin_shohin" "shugnon" "somon" "spitamen" "tabashar" "temurmalik" "tojikobod"
  //   "yovon" "zafarobod"

  Потребитель: {
    ru: "Потребитель",
    en: "Consumer",
  },
  Торговля: {
    ru: "Торговля",
    en: "Trade",
  },
  Ипотека: {
    ru: "Ипотека",
    en: "Mortgage",
  },
  Производство: {
    ru: "Производство",
    en: "Production",
  },
  "Розничная торговля": {
    ru: "Розничная торговля",
    en: "Retail",
  },
  Услуги: {
    ru: "Услуги",
    en: "Service",
  },
  "Наличие телефона": {
    ru: "Наличие телефона",
    en: "Phone OS",
  },
  IOS: {
    ru: "IOS",
    en: "IOS",
  },
  Android: {
    ru: "Android",
    en: "Android",
  },
  Другой: {
    ru: "Другой",
    en: "Other",
  },
  Любые: {
    ru: "Любые",
    en: "Any",
  },
  "Наличие автомобиля": {
    ru: "Наличие автомобиля",
    en: "Car availability type",
  },
  Аренда: {
    ru: "Аренда",
    en: "Rent",
  },
  Собственность: {
    ru: "Собственность",
    en: "Ownership",
  },
  "В кредите": {
    ru: "В кредите",
    en: "On credit",
  },
  "Наличие жилья": {
    ru: "Наличие жилья",
    en: "Housing availability",
  },
  "Сфера занятости": {
    ru: "Сфера занятости",
    en: "Employment sector",
  },
  "Государственное учреждение": {
    ru: "Государственное учреждение",
    en: "Government agency",
  },
  "Частная компания": {
    ru: "Частная компания",
    en: "Private company",
  },
  "Общественная организация": {
    ru: "Общественная организация",
    en: "Public organization",
  },
  "Индивидуальный предприниматель": {
    ru: "Индивидуальный предприниматель",
    en: "Individual entrepreneur",
  },
  "Статус деятельности": {
    ru: "Статус деятельности",
    en: "Employment status",
  },
  Занят: {
    ru: "Работник",
    en: "Employed",
  },
  Безработный: {
    ru: "Безработный",
    en: "Unemployed",
  },
  "Опыт работы в годах": {
    ru: "Опыт работы в годах",
    en: "Work experience in years",
  },
  "Поручитель/Созаемщик": {
    ru: "Поручитель/Созаемщик",
    en: "Co-borrower",
  },
  Залог: {
    ru: "Залог",
    en: "Collateral",
  },
  "Оценка БКИТ": {
    ru: "Оценка БКИТ",
    en: "Credit Bureau score",
  },
  "Номер телефона клиента": {
    ru: "Номер телефона клиента",
    en: "Phone number",
  },
  "Электронная почта клиента": {
    ru: "Электронная почта клиента",
    en: "Email",
  },
  "Кредит во время экстренного периода": {
    ru: "Кредит во время экстренного периода",
    en: "Loan during crisis period",
  },
  // "Кредит во время экстренного периода": {
  //   ru:"Кредит во время экстренного периода",
  //   en: "Loan during crisis period"
  // },
  Мин: {
    ru: "мин",
    en: "min",
  },
  Макс: {
    ru: "макс",
    en: "mix",
  },
  "Индекс потребительских цен": {
    ru: "Индекс потребительских цен",
    en: "Consumer price index",
  },
  "Нет машина": {
    ru: "Нет",
    en: "No car",
  },
  "Извините, чтобы подать заявку на кредит, вам должно быть опыт работы от 1 до 50 лет.": {
    ru: "Извините, чтобы подать заявку на кредит, вам должно быть опыт работы от 1 до 50 лет.",
    en: "Sorry, to apply for a loan, you must have 1 to 50 years of experience.",
  },
  "Извините, чтобы получить кредит, вы должны иметь ежемесячный доход не менее 1000": {
    ru: "Извините, чтобы получить кредит, вы должны иметь ежемесячный доход не менее 1000.",
    en: "Sorry, to be eligible for a loan application you must have at least 1000 monthly income.",
  },
  "Извините, чтобы получить кредит, вам должно быть от 18 до 65 лет.": {
    ru: "Извините, чтобы получить кредит, вам должно быть от 18 до 65 лет.",
    en: "Sorry, to be eligible for a loan application you must be between 18 - 65 y.o.",
  },
  "Минимальный возраст для кредита 18 лет": {
    ru: "Минимальный возраст для кредита 18 лет.",
    en: "Minimum age for the loan is 18 years.",
  },
  "Максимальный возраст для кредита 65 лет": {
    ru: "Максимальный возраст для кредита 65 лет.",
    en: "Maximum age for the loan 65 years.",
  },
  "Максимальный опыт работы для кредита 50 лет.": {
    ru: "Максимальный опыт работы для кредита 50 лет.",
    en: "Maximum work experience for the loan 50 years.",
  },
  "Минимальный ежемесячный доход для кредита 1000.": {
    ru: "Минимальный ежемесячный доход для кредита 1000.",
    en: "Minimum monthly income for the loan 1000.",
  },

  
};

export default translator;
