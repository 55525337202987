import React from "react";
import { useParams } from "react-router-dom";

import request from "request";
import translator from "translator";
import Input from "components/input";
import Button from "components/button";
import Loading from "components/loading";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import { useDispatch, useSelector } from "store/hooks";
import global from "global";
import styles from "./scoring.module.scss";
import ResultBox from "components/result-box";

const initialValidationState = {
  age: "",
  mon_income: "",
  work_exp: "",
};

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();
  const [pending, setPending] = React.useState(false);

  const [age, setAge] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("+992");
  const [email, setEmail] = React.useState("");
  const [backspace, setBackspace] = React.useState(false);
  const [monthly_income, setMonthly_income] = React.useState("");
  const [dependents, setDependents] = React.useState("");
  const [credit_history_count, setCredit_history_count] = React.useState("");
  const [work_exp, setWork_exp] = React.useState("");
  const [has_guarantor, setHas_guarantor] = React.useState({
    key: "",
    value: "",
  });
  const [has_collateral, setHas_collateral] = React.useState({
    key: "",
    value: "",
  });
  const [sector, setSector] = React.useState({
    key: "",
    value: "",
  });
  const [gender, setGender] = React.useState({
    key: "",
    value: "",
  });
  const [marital_status, setMarital_status] = React.useState({
    key: "",
    value: "",
  });
  const [education, setEducation] = React.useState({
    key: "",
    value: "",
  });
  const [branch, setBranch] = React.useState({
    key: "",
    value: "",
  });
  const [phone_type, setPhone_type] = React.useState({
    key: "",
    value: "",
  });
  const [has_transport, setHas_transport] = React.useState({
    key: "",
    value: "",
  });
  const [has_house, setHas_house] = React.useState({
    key: "",
    value: "",
  });
  const [work_place, setWork_place] = React.useState({
    key: "",
    value: "",
  });
  const [job_status, setJob_status] = React.useState({
    key: "",
    value: "",
  });
  const [is_disaster, setIs_disaster] = React.useState({
    key: "",
    value: "",
  });
  const [bkit_score, setBkit_score] = React.useState({
    key: "",
    value: "",
  });
  const [validationError, setValidationError] = React.useState(
    initialValidationState
  );

  const selectedRecommendation = React.useMemo(() => {
    return contextApplication.data?.recommendations_log?.find(
      (rec) => rec.id === contextApplication.data?.selected_recommendation
    );
  }, [
    contextApplication.data?.selected_recommendation,
    contextApplication.data?.recommendations_log,
  ]);

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;

    let newValidationError = { ...initialValidationState };
    if (Number(age) < 18)
      newValidationError.age = `${translator["Минимальный возраст для кредита 18 лет"][lang]}`;
    if ( Number(age) > 65)
      newValidationError.age = `${translator["Максимальный возраст для кредита 65 лет"][lang]}`;
    if (Number(monthly_income) < 1000)
      newValidationError.mon_income = `${translator["Минимальный ежемесячный доход для кредита 1000."][lang]}`;
    if (Number(work_exp) > 50)
      newValidationError.work_exp = `${translator["Максимальный опыт работы для кредита 50 лет."][lang]}`;

    setValidationError(newValidationError);
    if (
      JSON.stringify(newValidationError) !==
      JSON.stringify(initialValidationState)
    )
      return;

    setPending(true);
    request
      .post(`/applications/${id}/scoring`, {
        age: Number(age),
        dependants: Number(dependents),
        sector: sector.key,
        gender: gender.key,
        marital_status: marital_status.key,
        education: education.key,
        district: branch.key,
        phone_type: phone_type.key,
        has_transport: has_transport.key,
        has_house: has_house.key,
        work_place: work_place.key,
        job_status: job_status.key,
        bkit_score: bkit_score.key,
        language: lang === "en" ? "eng" : "rus",
        credit_history_count: Number(credit_history_count),
        interest_rate_loans: Number(contextApplication.data?.duration),
        mon_income: Number(monthly_income),
        work_exp: Number(work_exp),
        has_guarantor: has_guarantor.key === "yes" ? true : false,
        has_collateral: has_collateral.key === "yes" ? true : false,
        is_disaster: is_disaster.key === "yes" ? true : false,
        phone_number: phoneNumber,
        email: email,
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(
          () => dispatch.application.FETCH_APPLICATION_BY_ID(id),
          1000
        );
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  return (
    <form className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>
            {translator["Кредитные данные"][lang]}
          </div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>{translator["Сумма"][lang]}</div>
              <div className={styles.value}>
                {prettierNumber(
                  Number(
                    selectedRecommendation?.amount ??
                      contextApplication.data?.amount
                  )
                )}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Длительность"][lang]}
              </div>
              <div className={styles.value}>
                {selectedRecommendation?.duration ??
                  contextApplication.data?.duration}{" "}
                {translator["мес."][lang]}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Процентная ставка"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.int_rate} %
              </div>
            </div>
            {/* <div className={styles.item}>
              <div className={styles.key}>{translator["ID клиента"][lang]}</div>
              <div className={styles.value}>{contextApplication.data?.inn}</div>
            </div> */}
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Порог отказа"][lang]}
              </div>
              <div className={styles.value}>
                {(contextApplication.data?.scoring_log?.threshold ??
                  macrodata?.threshold ??
                  0) * 100}{" "}
                %
              </div>
            </div>
            {contextApplication.data?.scoring_log && (
              <>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {translator["Кредитная история"][lang]}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log?.credit_history_count}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {translator["Цель кредита"][lang]}
                  </div>
                  <div className={styles.value}>
                    {transcript.sector.getKeyFromValue(
                      contextApplication.data?.scoring_log?.sector
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {translator["Поручитель/Созаемщик"][lang]}
                  </div>
                  <div className={styles.value}>
                    {transcript.has_guarantor.getKeyFromValue(
                      contextApplication.data.scoring_log.has_guarantor
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>{translator["Залог"][lang]}</div>
                  <div className={styles.value}>
                    {transcript.has_collateral.getKeyFromValue(
                      contextApplication.data.scoring_log.has_collateral
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {translator["Кредит во время экстренного периода"][lang]}
                  </div>
                  <div className={styles.value}>
                    {transcript.is_disaster.getKeyFromValue(
                      contextApplication.data?.scoring_log?.is_disaster
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {translator["Оценка БКИТ"][lang]}
                  </div>
                  <div className={styles.value}>
                  {transcript.bkit_score.getKeyFromValue(
                      contextApplication.data?.scoring_log?.bkit_score
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>{translator["Макроданные"][lang]}</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Инфляция на продовольственные товары"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.food_inflation ??
                  macrodata?.food_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Инфляция непотребительских товаров"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.nonfood_inflation ??
                  macrodata?.nonfood_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Инфляция услуг"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.services_inflation ??
                  macrodata?.services_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Курс доллара"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.exrate_usd_avg ??
                  macrodata?.exrate_usd_avg}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Средний доход населения"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.avrate ??
                  macrodata?.avrate}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>{translator["ВВП"][lang]}</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.gdp ?? macrodata?.gdp}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Индекс потребительских цен"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.cpi ?? macrodata?.cpi}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                {translator["Цена на нефть марки Брент"][lang]}
              </div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.brent_oil_price ??
                  macrodata?.brent_oil_price}
              </div>
            </div>
          </div>
        </div>
        {contextApplication.data?.scoring_log && (
          <div className={styles.creditDataBox}>
            <div className={styles.title}>
              {translator["Данные клиента"][lang]}
            </div>
            <div className={styles.list}>
              <div className={styles.item}>
                <div className={styles.key}>{translator["Возраст"][lang]}</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.age}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Образование"][lang]}
                </div>
                <div className={styles.value}>
                  {transcript.educationEng.getValueFromKey(
                    contextApplication.data.scoring_log.education
                  )}
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.key}>{translator["Город"][lang]}</div>
                <div className={styles.value}>
                  {transcript.district.getKeyFromValue(
                    contextApplication.data?.scoring_log.district
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Статус деятельности"][lang]}
                </div>
                <div className={styles.value}>
                  {transcript.job_status.getKeyFromValue(
                    contextApplication.data.scoring_log.job_status
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>{translator["Пол"][lang]}</div>
                <div className={styles.value}>
                  {transcript.gender.getKeyFromValue(
                    contextApplication.data.scoring_log.gender
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Семейное положение"][lang]}
                </div>
                <div className={styles.value}>
                  {transcript.marital_status.getKeyFromValue(
                    contextApplication.data.scoring_log.marital_status
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Ежемесячный доход"][lang]}
                </div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.mon_income}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Количество иждивиенцев"][lang]}
                </div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.dependants}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Опыт работы в годах"][lang]}
                </div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.work_exp}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Наличие автомобиля"][lang]}
                </div>
                <div className={styles.value}>
                  {transcript.isCarСode.getKeyFromValue(
                    contextApplication.data.scoring_log.has_transport
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Наличие жилья"][lang]}
                </div>
                <div className={styles.value}>
                  {transcript.isHomeСode.getKeyFromValue(
                    contextApplication.data.scoring_log.has_house
                  )}
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Сфера занятости"][lang]}
                </div>
                <div className={styles.value}>
                  {transcript.work_place.getKeyFromValue(
                    contextApplication.data?.scoring_log?.work_place
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Наличие телефона"][lang]}
                </div>
                <div className={styles.value}>
                  {transcript.phone_type.getKeyFromValue(
                    contextApplication.data?.scoring_log?.phone_type
                  )}
                </div>
              </div>

              {String(contextApplication.data?.scoring_log.phone_number) !==
                "+992" && (
                <div className={styles.item}>
                  <div className={styles.key}>
                    {translator["Номер телефона клиента"][lang]}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log.phone_number}
                  </div>
                </div>
              )}
              {contextApplication.data?.scoring_log.email !== "" && (
                <div className={styles.item}>
                  <div className={styles.key}>
                    {translator["Электронная почта клиента"][lang]}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log.email}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {!contextApplication.data?.scoring_log &&
          !contextApplication.data?.new_scoring_log && (
            <>
              <div className={styles.fieldGroup}>
                <div className={styles.fieldTitle}>
                  {translator["Кредитные данные"][lang]}
                </div>
                <div className={styles.fieldList}>
                  <Input
                    title={translator["Кредитная история"][lang]}
                    value={credit_history_count}
                    type="integer"
                    min={1}
                    step={1}
                    onInput={(value) => setCredit_history_count(value)}
                  />
                  <Input
                    title={translator["Цель кредита"][lang]}
                    value={sector.key}
                    type="select"
                    options={[
                      {
                        key: "agriculture",
                        value: translator["Сельское хозяйство"][lang],
                      },
                      {
                        key: "consumer",
                        value: translator["Потребительский"][lang],
                      },
                      {
                        key: "mortgage",
                        value: translator["Ипотека"][lang],
                      },
                      {
                        key: "production",
                        value: translator["Производство"][lang],
                      },
                      {
                        key: "retail",
                        value: translator["Розничная торговля"][lang],
                      },
                      {
                        key: "service",
                        value: translator["Услуги"][lang],
                      },
                      {
                        key: "trade",
                        value: translator["Торговля"][lang],
                      },
                    ]}
                    onSelect={(option) => setSector(option)}
                  />
                  <Input
                    title={translator["Поручитель/Созаемщик"][lang]}
                    id="has_guarantor"
                    type="select"
                    value={has_guarantor.key}
                    onSelect={(value) => setHas_guarantor(value)}
                    options={[
                      {
                        key: "Yes",
                        value: translator["Да"][lang],
                      },
                      {
                        key: "No",
                        value: translator["Нет кредит"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={translator["Залог"][lang]}
                    id="has_collateral"
                    type="select"
                    value={has_collateral.key}
                    onSelect={(value) => setHas_collateral(value)}
                    options={[
                      {
                        key: "Yes",
                        value: translator["Да"][lang],
                      },
                      {
                        key: "No",
                        value: translator["Нет кредит"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={
                      translator["Кредит во время экстренного периода"][lang]
                    }
                    id="lendingType"
                    value={is_disaster.key}
                    type="select"
                    onSelect={(option) => setIs_disaster(option)}
                    options={[
                      {
                        key: "Yes",
                        value: translator["Да"][lang],
                      },
                      {
                        key: "No",
                        value: translator["Нет кредит"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={translator["Оценка БКИТ"][lang]}
                    id="lendingType"
                    value={bkit_score.key}
                    type="select"
                    onSelect={(option) => setBkit_score(option)}
                    options={[
                      {
                        key: "a",
                        value: "A",
                      },
                      {
                        key: "b",
                        value: "B",
                      },
                      {
                        key: "c",
                        value: "C",
                      },
                      {
                        key: "d",
                        value: "D",
                      },
                      {
                        key: "e",
                        value: "E",
                      },
                      {
                        key: "f",
                        value: "F",
                      },
                      {
                        key: "g",
                        value: "G",
                      },
                      {
                        key: "h",
                        value: "H",
                      },
                      {
                        key: "i",
                        value: "I",
                      },
                      {
                        key: "j",
                        value: "J",
                      },
                      {
                        key: "k",
                        value: "K",
                      },
                      {
                        key: "l",
                        value: "L",
                      },
                      {
                        key: "m",
                        value: "M",
                      },
                      {
                        key: "n",
                        value: "N",
                      },
                      {
                        key: "o",
                        value: "O",
                      },
                      {
                        key: "p",
                        value: "P",
                      },
                      {
                        key: "q",
                        value: "Q",
                      },
                      {
                        key: "r",
                        value: "R",
                      },
                      {
                        key: "s",
                        value: "S",
                      },
                      {
                        key: "t",
                        value: "T",
                      },
                      {
                        key: "u",
                        value: "U",
                      },
                      {
                        key: "v",
                        value: "V",
                      },
                      {
                        key: "w",
                        value: "W",
                      },
                      {
                        key: "x",
                        value: "X",
                      },
                      {
                        key: "y",
                        value: "Y",
                      },
                      {
                        key: "z",
                        value: "Z",
                      },
                      {
                        key: "none",
                        value: translator["Нет"][lang],
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={styles.fieldGroup}>
                <div className={styles.fieldTitle}>
                  {translator["Данные клиента"][lang]}
                </div>
                <div className={styles.fieldList}>
                  <Input
                    title={translator["Возраст"][lang]}
                    value={age}
                    type="integer"
                    min={1}
                    max={100}
                    step={1}
                    error={validationError.age}
                    placeholder={`${translator["Мин"][lang]}: 18 ${translator["Макс"][lang]}: 65`}
                    onInput={(value) => setAge(value)}
                  />
                  <Input
                    title={translator["Количество иждивиенцев"][lang]}
                    value={dependents}
                    type="integer"
                    min={0}
                    max={24}
                    step={1}
                    onInput={(value) => setDependents(value)}
                  />

                  <Input
                    title={translator["Ежемесячный доход"][lang]}
                    value={monthly_income}
                    type="integer"
                    min={1}
                    step={100}
                    error={validationError.mon_income}
                    placeholder={`${translator["Мин"][lang]}: 1000`}
                    onInput={(value) => setMonthly_income(value)}
                  />
                  <Input
                    title={translator["Опыт работы в годах"][lang]}
                    value={work_exp}
                    type="integer"
                    min={1}
                    step={1}
                    max={100}
                    error={validationError.work_exp}
                    placeholder={`${translator["Мин"][lang]}: 1 ${translator["Макс"][lang]}: 50`}
                    onInput={(value) => setWork_exp(value)}
                  />
                  <Input
                    title={translator["Пол"][lang]}
                    id="gender"
                    value={gender.key}
                    type="select"
                    options={[
                      {
                        key: "male",
                        value: translator["Мужской"][lang],
                      },
                      {
                        key: "female",
                        value: translator["Женский"][lang],
                      },
                    ]}
                    onSelect={(value) => setGender(value)}
                  />
                  <Input
                    title={translator["Семейное положение"][lang]}
                    id="marital_status"
                    value={marital_status.key}
                    type="select"
                    options={[
                      {
                        key: "divorced",
                        value: translator["Разведён/Разведена"][lang],
                      },
                      {
                        key: "married",
                        value: translator["Женат/Замужем"][lang],
                      },
                      {
                        key: "widow/widower",
                        value: translator["Вдовец/Вдова"][lang],
                      },
                      {
                        key: "single",
                        value: translator["Не женат/Не замужем"][lang],
                      },
                    ]}
                    onSelect={(value) => setMarital_status(value)}
                  />
                  <Input
                    title={translator["Образование"][lang]}
                    id="education"
                    value={education.key}
                    type="select"
                    options={[
                      {
                        key: "secondary_education",
                        value: translator["Среднее образование"][lang],
                      },
                      {
                        key: "higher_education",
                        value: translator["Высшее образование"][lang],
                      },
                      {
                        key: "incomplete_secondary_education",
                        value: translator["Неполное среднее образование"][lang],
                      },
                      {
                        key: "secondary_with_specialisation",
                        value:
                          translator["Среднее специальное образование"][lang],
                      },
                      {
                        key: "other_education",
                        value: translator["Другое образование"][lang],
                      },
                    ]}
                    onSelect={(value) => setEducation(value)}
                  />
                  <Input
                    title={translator["Город"][lang]}
                    id="branch"
                    value={branch.key}
                    type="select"
                    onSelect={(value) => setBranch(value)}
                    options={[
                      {
                        key: "asht",
                        value: translator["Ашт"][lang],
                      },
                      {
                        key: "ayni",
                        value: translator["Айни"][lang],
                      },
                      {
                        key: "b.gafurov",
                        value: translator["Б.гафуров"][lang],
                      },
                      {
                        key: "baljuvon",
                        value: translator["Бальджуван"][lang],
                      },
                      {
                        key: "balkhi",
                        value: translator["Балхи"][lang],
                      },
                      {
                        key: "bokhtar",
                        value: translator["Бохтар"][lang],
                      },
                      {
                        key: "buston",
                        value: translator["Бустон"][lang],
                      },
                      {
                        key: "vahdat",
                        value: translator["Вахдат"][lang],
                      },
                      {
                        key: "vakhsh",
                        value: translator["Вахш"][lang],
                      },
                      {
                        key: "vanj",
                        value: translator["Вандж"][lang],
                      },
                      {
                        key: "varzob",
                        value: translator["Варзоб"][lang],
                      },
                      {
                        key: "vose",
                        value: translator["Восе"][lang],
                      },
                      {
                        key: "dangara",
                        value: translator["Дангара"][lang],
                      },
                      {
                        key: "chirik",
                        value: translator["Чирик"][lang],
                      },
                      {
                        key: "darvoz",
                        value: translator["Дарвоз"][lang],
                      },
                      {
                        key: "devashtich",
                        value: translator["Деваштич"][lang],
                      },
                      {
                        key: "dushanbe",
                        value: translator["Душанбе"][lang],
                      },
                      {
                        key: "dusti",
                        value: translator["Дусти"][lang],
                      },
                      {
                        key: "farhor",
                        value: translator["Фархор"][lang],
                      },
                      {
                        key: "farovon",
                        value: translator["Фараван"][lang],
                      },
                      {
                        key: "fayzobod",
                        value: translator["Файзабад"][lang],
                      },
                      {
                        key: "firdavsi",
                        value: translator["Фирдавси"][lang],
                      },
                      {
                        key: "guliston",
                        value: translator["Гулистан"][lang],
                      },
                      {
                        key: "hamadoni",
                        value: translator["Хамадони"][lang],
                      },
                      {
                        key: "hissor",
                        value: translator["Хиссар"][lang],
                      },
                      {
                        key: "isfara",
                        value: translator["Исфара"][lang],
                      },
                      {
                        key: "istaravshan",
                        value: translator["Истаравшан"][lang],
                      },
                      {
                        key: "istiqlol",
                        value: translator["Истикляль"][lang],
                      },
                      {
                        key: "j.rasulov",
                        value: translator["Дж.Расулов"][lang],
                      },
                      {
                        key: "jayhun",
                        value: translator["Джайхун"][lang],
                      },
                      {
                        key: "jomi",
                        value: translator["Джоми"][lang],
                      },
                      {
                        key: "kangurt",
                        value: translator["Кангурт"][lang],
                      },
                      {
                        key: "kushoniyon",
                        value: translator["Кушониен"][lang],
                      },
                      {
                        key: "khorug",
                        value: translator["Хоруг"][lang],
                      },
                      {
                        key: "khovaling",
                        value: translator["Хавалинг"][lang],
                      },
                      {
                        key: "khujand",
                        value: translator["Худжанд"][lang],
                      },
                      {
                        key: "khuroson",
                        value: translator["Хуросан"][lang],
                      },
                      {
                        key: "kubodiyon",
                        value: translator["Кубодян"][lang],
                      },
                      {
                        key: "konibodom",
                        value: translator["Конибодом"][lang],
                      },
                      {
                        key: "kuhistoni_mastchoh",
                        value: translator["Мастчох"][lang],
                      },
                      {
                        key: "kulob",
                        value: translator["Куляб"][lang],
                      },
                      {
                        key: "muminobod",
                        value: translator["Муминабад"][lang],
                      },
                      {
                        key: "murgob",
                        value: translator["Мургоб"][lang],
                      },
                      {
                        key: "norak",
                        value: translator["Норак"][lang],
                      },
                      {
                        key: "nurobod",
                        value: translator["Нуробод"][lang],
                      },
                      {
                        key: "panj",
                        value: translator["Панч"][lang],
                      },
                      {
                        key: "panjakent",
                        value: translator["Панджакет"][lang],
                      },
                      {
                        key: "rasht",
                        value: translator["Рашт"][lang],
                      },
                      {
                        key: "rogun",
                        value: translator["Рогун"][lang],
                      },
                      {
                        key: "rudaki",
                        value: translator["Рудаки"][lang],
                      },
                      {
                        key: "sangtuda",
                        value: translator["Cангтуда"][lang],
                      },
                      {
                        key: "sangvor",
                        value: translator["Сангвор"][lang],
                      },
                      {
                        key: "tursunzoda",
                        value: translator["Турсунзаде"][lang],
                      },
                      {
                        key: "tojikobod",
                        value: translator["Таджикабад"][lang],
                      },
                      {
                        key: "temurmalik",
                        value: translator["Темурмалик"][lang],
                      },
                      {
                        key: "shahrinav",
                        value: translator["Шахринав"][lang],
                      },
                      {
                        key: "shahrituz",
                        value: translator["Шахритуз"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={translator["Наличие телефона"][lang]}
                    id="phone_type"
                    type="select"
                    value={phone_type.key}
                    onSelect={(value) => setPhone_type(value)}
                    options={[
                      {
                        key: "ios",
                        value: translator["IOS"][lang],
                      },
                      {
                        key: "android",
                        value: translator["Android"][lang],
                      },
                      {
                        key: "any",
                        value: translator["Нет телефон"][lang],
                      },
                      {
                        key: "other",
                        value: translator["Другой"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={translator["Наличие автомобиля"][lang]}
                    id="has_transport"
                    type="select"
                    value={has_transport.key}
                    onSelect={(value) => setHas_transport(value)}
                    options={[
                      {
                        key: "rent",
                        value: translator["Аренда"][lang],
                      },
                      {
                        key: "ownership",
                        value: translator["Собственность"][lang],
                      },
                      {
                        key: "on_credit",
                        value: translator["В кредите"][lang],
                      },
                      {
                        key: "other",
                        value: translator["Другой"][lang],
                      },
                      {
                        key: "none",
                        value: translator["Нет машина"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={translator["Наличие жилья"][lang]}
                    id="has_house"
                    type="select"
                    value={has_house.key}
                    onSelect={(value) => setHas_house(value)}
                    options={[
                      {
                        key: "rent",
                        value: translator["Аренда"][lang],
                      },
                      {
                        key: "ownership",
                        value: translator["Собственность"][lang],
                      },
                      {
                        key: "on_credit",
                        value: translator["В кредите"][lang],
                      },
                      {
                        key: "other",
                        value: translator["Другой"][lang],
                      },
                      {
                        key: "none",
                        value: translator["Нет"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={translator["Сфера занятости"][lang]}
                    id="work_place"
                    type="select"
                    value={work_place.key}
                    onSelect={(value) => setWork_place(value)}
                    options={[
                      {
                        key: "government_agency",
                        value: translator["Государственное учреждение"][lang],
                      },
                      {
                        key: "private_company",
                        value: translator["Частная компания"][lang],
                      },
                      {
                        key: "public_organization",
                        value: translator["Общественная организация"][lang],
                      },
                      {
                        key: "individual_entrepreneur",
                        value:
                          translator["Индивидуальный предприниматель"][lang],
                      },
                      {
                        key: "other",
                        value: translator["Другой"][lang],
                      },
                      {
                        key: "none",
                        value: translator["Нет кредит"][lang],
                      },
                    ]}
                  />
                  <Input
                    title={translator["Статус деятельности"][lang]}
                    id="job_status"
                    type="select"
                    value={job_status.key}
                    onSelect={(value) => setJob_status(value)}
                    options={[
                      {
                        key: "employed",
                        value: translator["Занят"][lang],
                      },
                      {
                        key: "unemployed",
                        value: translator["Безработный"][lang],
                      },
                      {
                        key: "other",
                        value: translator["Другой"][lang],
                      },
                    ]}
                  />

                  <Input
                    title={translator["Номер телефона клиента"][lang]}
                    value={phoneNumber}
                    type="string"
                    placeholder={"+992-XX-XXX-XXXX"}
                    onKeyDown={(event: { keyCode: number }) => {
                      if (event.keyCode === 8) {
                        setBackspace(true);
                      } else {
                        setBackspace(false);
                      }
                    }}
                    onInput={(value) => {
                      if (value[0] === "+" && value.length < 17) {
                        if (!backspace) {
                          if (value.length > 4 && value[4] !== "-") {
                            value = value.slice(0, 4) + "-" + value.slice(4);
                          }
                          if (value.length > 7 && value[7] !== "-") {
                            value = value.slice(0, 7) + "-" + value.slice(7);
                          }
                          if (value.length > 11 && value[11] !== "-") {
                            value = value.slice(0, 11) + "-" + value.slice(11);
                          }
                        }

                        if (Number(value.substring(1).replaceAll("-", ""))) {
                          setPhoneNumber(value.trim());
                        } else if (value.length === 1) {
                          setPhoneNumber(value.trim());
                        }
                      } else if (!value.substring(1).trim()) {
                        setPhoneNumber("+992");
                      }
                    }}
                  />
                  <Input
                    title={translator["Электронная почта клиента"][lang]}
                    type="string"
                    value={email}
                    onInput={(value) => setEmail(value)}
                  />
                </div>
              </div>
              <div className={styles.footerBox}>
                <div className={styles.buttonGroup}>
                  <Button pending={pending} onClick={handleCalculate}>
                    {translator["Запустить скоринг"][lang]}
                  </Button>
                </div>
              </div>
            </>
          )}
      </div>
      <div className={styles.result}>
        <ResultBox />
      </div>
    </form>
  );
};

export default Scoring;
