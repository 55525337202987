import React from "react";

import loading from "./loading";
import styles from "./loading.module.scss";

const Loading = ({ size = 50 }: loading.props) => {
  return <div className={styles.ldsDualRing} style={{ width: size + "px", height: size + "px" }}></div>;
};

export default Loading;
