import React from "react";
import global from "global";
import classNames from "classnames";
import { toast } from "react-toastify";

import prettierNumber from "utils/prettier-number";
import { useDispatch, useSelector } from "store/hooks";
import Button from "components/button";
import request from "request";
import styles from "./recommendation.module.scss";
import translator from "translator";

const Modal = ({
  onClose,
  application,
}: {
  onClose: () => void;
  application: global.application | null;
}) => {
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const [selectedRecommendation, setSelectedRecommendation] =
    React.useState<global.recommendation>();
  const [pending, setPending] = React.useState(false);

  const handleSelectRecommendation = (
    recommendation: global.recommendation
  ) => {
    setSelectedRecommendation(recommendation);
  };

  const handleSubmit = () => {
    if (!selectedRecommendation)
      toast.warning("Пожалуйста, выберите рекомендацию");
    setPending(true);
    request
      .post(
        `/applications/${application?.id}/recommendation/${selectedRecommendation?.id}`
      )
      .then(() => {
        dispatch.application.FETCH_APPLICATION_BY_ID(application?.id);
      })
      .finally(() => {
        setPending(false);
        onClose();
      });
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <div
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>{translator["Рекомендации"][lang]}</div>
        <div className={styles.recommendations}>
          {application?.recommendations_log.map((recommendation, i) => (
            <button
              type="button"
              onClick={() => handleSelectRecommendation(recommendation)}
              className={classNames(
                styles.recommendation,
                selectedRecommendation?.id === recommendation.id &&
                  styles.selected
              )}
              key={recommendation.id}
            >
              <div className={styles.smallTitle}>
                {translator["Рекомендация"][lang]} {i + 1}
              </div>
              <div className={styles.item}>
                <div className={styles.key}>{translator["Сумма"][lang]}</div>
                <div className={styles.value}>
                  {prettierNumber(recommendation.amount)}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Длительность"][lang]}
                </div>
                <div className={styles.value}>
                  {recommendation.duration} {translator["мес."][lang]}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Вероятность выплаты"][lang]}
                </div>
                <div className={styles.value}>
                  {(recommendation.good_prob * 100).toFixed()} %
                </div>
              </div>
              {/* <div className={styles.item}>
                <div className={styles.key}>
                  {translator["Ежемесячный платеж"][lang]}
                </div>
                <div className={styles.value}>
                  {prettierNumber(recommendation.mon_payment)} $
                </div>
              </div> */}
            </button>
          ))}
        </div>
        <div className={styles.buttonGroup}>
          <Button pending={pending} onClick={handleSubmit}>
            {translator["Подтверить"][lang]}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
