import React from "react";
import { useNavigate } from "react-router-dom";

import Input from "components/input";
import Button from "components/button";
import request from "request";
import styles from "./create-modal.module.scss";
import translator from "translator";
import { useSelector } from "store/hooks";

const Modal = ({ onClose }: { onClose: () => void }) => {
  // const [inn, setInn] = React.useState("");
  const [sum, setSum] = React.useState("");
  const [interestRate, setInterestRate] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "inn" | "sum" | "interestRate" | "duration";
    value: string;
  } | null>(null);

  const navigate = useNavigate();
  const lang = useSelector((state) => state.lang);

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (inn.trim().length !== 12) {
    //   return setValidError({
    //     name: "inn",
    //     value: translator["ID клиента должна иметь 12 цифр"][lang],
    //   });
    // } else 
    if (sum.trim().length === 0) {
      return setValidError({
        name: "sum",
        value: translator["Сумма обязательна"][lang],
      });
    } else if (interestRate.trim().length === 0) {
      return setValidError({
        name: "interestRate",
        value: translator["Процентная ставка обязательна"][lang],
      });
    } else if (duration.trim().length === 0) {
      return setValidError({
        name: "duration",
        value: translator["Длительность кредита обязательна"][lang],
      });
    } else setValidError(null);
    setPending(true);
    request
      .post("/applications", {
        amount: Number(sum),
        int_rate: interestRate,
        duration: duration,
        // inn: inn,
      })
      .then((res) => res.data)
      .then((data) => {
        navigate(`application/${data.id}/scoring`);
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>{translator["Новая заявка"][lang]}</div>
        <div className={styles.inputs}>
          {/* <div className={styles.input}>
            <Input
              title={translator["ID клиента"][lang]}
              type="string"
              value={inn}
              onInput={(value) => setInn(value)}
              maxLength={12}
            />
            {validError?.name === "inn" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div> */}
          <div className={styles.input}>
            <Input
              title={translator["Сумма"][lang]}
              type="integer"
              value={sum}
              onInput={(value) => setSum(value)}
              step={100}
              min={1}
            />
            {validError?.name === "sum" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title={translator["Процентная ставка"][lang]}
              type="float"
              value={interestRate}
              onInput={(value) => setInterestRate(value)}
              step={1}
              min={1}
            />
            <span className={styles.percentage}>%</span>
            {validError?.name === "interestRate" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title={translator["Длительность"][lang]}
              type="float"
              value={duration}
              onInput={(value) => setDuration(value)}
              step={1}
              min={1}
            />
            {validError?.name === "duration" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            {translator["Отмена"][lang]}
          </Button>
          <Button type="submit" color="green" pending={pending}>
            {translator["Создать"][lang]}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
