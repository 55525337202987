import isDev from "./is-dev";

// const devURL = "http://192.168.1.43:5000";
const devURL = "https://retail.westeurope.cloudapp.azure.com";
// const devURL = "http://10.30.230.69:8000";

const prodURL = "https://retail.westeurope.cloudapp.azure.com";

// const prodURL = "http://10.30.230.69:8000";

const baseURL = isDev ? devURL : prodURL;

export default baseURL;